// these slugs and values should not be changed as these are comming from strapi and used for routing puposes

export const general_insurance_slug = "general-insurance";
export const life_insurance_slug = "life-insurance";
export const for_businesses_slug = "for-businesses";
export const claims_slug  = "claims";
export const support_slug  = "support";
export const login_and_quote = "login-and-quote"
export const login_action = "login"
export const quote_action = "get_quote"


export const speak_to_advisor = "speak_to_advisor";
export const call_us = "call_us";
export const s_and_p_plan_slug = "savings-and-protection-plans";
export const online_plans_slug = "online-plans";
export const protection_plans_slug = "protection-plans";
export const phoneNumber = "17130000";
