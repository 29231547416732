import React from "react";
import Modal from "../../../components/common/modal/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

const ConformationModal = ({ showModal, toggleModal, thanks, thanksbutton }) => {
  const translate = useLanguageTranslate();

  const handleClose = (e) => {
    e.preventDefault();
    toggleModal();
  };

  return (
    <div >
      <Modal show={showModal}>
        <div>
          <div className="bg-white">
            <div className="order-1">
              <FontAwesomeIcon
                icon="fa-solid fa-circle-check"
                className="text-[40px] text-green-500 mb-3"
              />
              <h1 className="confirmation-title" >
                {translate(thanks?.title_en, thanks?.title_ar)}
              </h1>
              <h2 className="mb-6 text-auto max-w-max">
                {translate(thanks?.description_en, thanks?.description_ar)}
              </h2>
              <div>
                <button
                  onClick={handleClose}
                  className="w-full text-black bg-blue-400 p-2 rounded-lg primary-button"
                >
                  {translate(thanksbutton?.label_en, thanksbutton?.label_ar)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConformationModal;
