import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../axiosInstance";
import { apiEndPoints } from "../../api/apiEndpoints";

const initialState = {
  header_menu: null,
  tools : null,
  isLoading: false,
  error: null,
};

export const fetchHeaderMenu = createAsyncThunk(
  "fetchHeaderMenu",
  (queryString, { rejectWithValue }) => {
    return Axios.get(
      `${apiEndPoints.parentMenusHeaderAndFooter}${queryString || ""}`
    ).then((res) => res.data);
  }
);

export const fetchConfigToolsInfo = createAsyncThunk(
  "fetchConfig",
  (queryString, { rejectWithValue }) => {
    return Axios.get(
      `${apiEndPoints.tools}`
    ).then((res) => res.data);
  }
);

export const toolsSlice = createSlice({
  name: "utils slice",
  initialState,
  reducers: {
    clearUtilsError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // FETCH  UTILS API

    builder.addCase(fetchConfigToolsInfo.pending, (state, action) => {
      if (!state.tools) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchConfigToolsInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tools = action.payload?.data?.attributes;
    });
    builder.addCase(fetchConfigToolsInfo.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });

    // HEADER MENU
    builder.addCase(fetchHeaderMenu.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchHeaderMenu.fulfilled, (state, action) => {
      state.isLoading = false;
      state.header_menu = action.payload?.data;
    });
    builder.addCase(fetchHeaderMenu.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });
  },
});

// SELECTORS
export const selectUtilsWhole = (store) => store?.tools;
export const selectHeaderMenu = (store) => store?.tools?.header_menu;
export const selectUtilsInfo = (store) => store?.tools?.tools;
export const selectUtilsLoader = (store) => store?.tools?.isLoading;

// ACTIONS TO DISPATCH
export const { clearUtilsError } = toolsSlice.actions;

export default toolsSlice.reducer;
