import React, { useEffect, useState } from 'react'
import { BASE_URL } from "../../../api/baseURL";
import { getSocialLinks } from '../../../api/apiActions';

const getClickableLink = link => {
  return link.startsWith("http://") || link.startsWith("https://") ?
    link
    : `https://${link}`;
};



const SocialLinks = () => {
    const [socialLinksData, setSocialLinksData] = useState(null);

    const getInitialSocialLinkData = async () => {
        try {
          const response = await getSocialLinks();
          const data = response.data;
          setSocialLinksData(data)
        } catch (error) {
          console.error("Error fetching blog data:", error);
        }
      };

      useEffect(() => {
        getInitialSocialLinkData();
      }, []);


  return (
    <>
    {socialLinksData && socialLinksData.length > 0 &&
    <div className="share-buttons-container flex space-x-4 items-center socialLinks py-4">
    {socialLinksData[0].attributes.social_icon.map((icon) => (
      <a key={icon.id}  href={getClickableLink(`${icon.social_url}`)} target="_blank" rel="noopener noreferrer" className=' copy-link-button flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden p-1 rounded-[64px] bg-[#eef0f2]'>
        <img src={`${BASE_URL}${icon.social_img.data.attributes.url}`} alt={icon.social_img.data.attributes.name} />
      </a>
    ))}
  </div>
    }
    </>
  )
}

export default SocialLinks
