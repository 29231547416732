import React from 'react'
import { Helmet } from "react-helmet-async";

const ReactHelmet = ({ meta_data }) => {
    if (!meta_data) return null;
    const { meta_title, meta_description, meta_keywords } = meta_data;
    return (
        <Helmet>
            <title>{meta_title}</title>
            <meta name="description" content={meta_description} />
            <meta name="keywords" content={meta_keywords} />

            {/* OG TAGS for future reference */}
            {/* <meta property="og:title" content="" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="" />
                <meta property="og:url" content="" /> */}

        </Helmet>

    )
}

export default ReactHelmet