import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nBackend from "i18next-http-backend";
import { en } from './locales/en/translation';
import { ar } from './locales/ar/translation';

// function getCurrentLang() {
//     // Your logic to determine the current language goes here
//     // For example, you might use localStorage or some other method to store the current language
//     return localStorage.getItem('language') || 'en'; // Default to English if language is not set
//   }
i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
      backend: {
       // translation file path
       loadPath: "/locales/translation/{{lng}}.json",
      },
    fallbackLng: "en",
    // lng: getCurrentLang(),
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en,
      ar,
    },
  });

export default i18n;