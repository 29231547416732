import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../axiosInstance";
import { apiEndPoints } from "../../api/apiEndpoints";

const initialState = {
  plans_header: null,
  plan_type: null,
  plan_detail: null,
  plan_type_config: null,
  plan_detail_config: null,
  isLoading: false,
  error: null,
};

export const fetchPlanHeader = createAsyncThunk(
  "fetchPlanHeader",
  (queryString, { rejectWithValue }) => {
    return Axios.get(
      `${apiEndPoints.plansHeaderRoutes}${queryString || ""}`
    ).then((res) => res.data);
  }
);
export const fetchPlanType = createAsyncThunk(
  "fetchPlanType",
  (queryString, { rejectWithValue }) => {
    return Axios.get(
      `${apiEndPoints.planTypeWithPlans}${queryString || ""}`
    ).then((res) => res.data);
  }
);
export const fetchPlanDetail = createAsyncThunk(
  "fetchPlanDetail",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`${apiEndPoints.planDetail}${queryString || ""}`).then(
      (res) => res.data
    );
  }
);
export const fetchPlanTypeConfig = createAsyncThunk(
  "fetchPlanTypeConfig",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`${apiEndPoints.planTypeConfig}${queryString || ""}`).then(
      (res) => res.data
    );
  }
);
export const fetchPlanDetailConfig = createAsyncThunk(
  "fetchPlanDetailConfig",
  (queryString, { rejectWithValue }) => {
    return Axios.get(
      `${apiEndPoints.planDetailConfig}${queryString || ""}`
    ).then((res) => res.data);
  }
);

export const plansSlice = createSlice({
  name: "plans_slice",
  initialState,
  reducers: {
    clearPlansError: (state) => {
      state.error = null;
    },
    clearPlanDetail: (state) => {
      state.plan_detail = null;
    },
    setPlanTypeConfig: (state, action) => {
      state.plan_type_config = action.payload;
    },
  },
  extraReducers: (builder) => {
    // PLAN HEADER API
    builder.addCase(fetchPlanHeader.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchPlanHeader.fulfilled, (state, action) => {
      state.isLoading = false;
      state.plans_header = action.payload?.data;
    });
    builder.addCase(fetchPlanHeader.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });

    // PLAN TYPES API
    builder.addCase(fetchPlanType.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchPlanType.fulfilled, (state, action) => {
      state.isLoading = false;
      state.plan_type = action.payload?.data;
    });
    builder.addCase(fetchPlanType.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });

    // PLAN DETAILS API
    builder.addCase(fetchPlanDetail.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchPlanDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.plan_detail = action.payload?.data;
    });
    builder.addCase(fetchPlanDetail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });

    // PLAN TYPE CONFIG API
    builder.addCase(fetchPlanTypeConfig.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchPlanTypeConfig.fulfilled, (state, action) => {
      state.isLoading = false;
      state.plan_type_config = action.payload?.data;
    });
    builder.addCase(fetchPlanTypeConfig.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });

    // PLAN DETAIL CONFIG
    builder.addCase(fetchPlanDetailConfig.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchPlanDetailConfig.fulfilled, (state, action) => {
      state.isLoading = false;
      state.plan_detail_config = action.payload?.data;
    });
    builder.addCase(fetchPlanDetailConfig.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });
  },
});

// SELECTORS
export const selectPlansWhole = (store) => store.plans;
export const selectPlanHeader = (store) => store?.plans?.plans_header;
export const selectPlanType = (store) => store?.plans?.plan_type;
export const selectPlanDetail = (store) => store?.plans?.plan_detail;
export const selectPlanTypeConfig = (store) => store?.plans?.plan_type_config;
export const selectPlanDetailConfig = (store) =>
  store?.plans?.plan_detail_config;
export const selectPlansError = (store) => store?.plans?.isLoading;

// ACTIONS TO DISPATCH
export const { clearPlanDetail, clearPlansError, setPlanTypeConfig } =
  plansSlice.actions;

export default plansSlice.reducer;
