import React, { useEffect } from "react";
import Layout from "./route/layout";
import ReactGA from "react-ga";
import useLanguageTranslate from "./hooks/useLanguageTranslate";
import CustomToastContainer from "./components/toastify/toastify";
import useLanguagePercistant from "./hooks/useLanguagePercistant";
import { useDispatch } from "react-redux";
import { fetchPlanHeader } from "./redux/features/plansSlice";
import { fetchConfigToolsInfo, fetchHeaderMenu } from "./redux/features/toolsSlice";
import ReactHelmet from "./components/common/ReactHelmet/ReactHelmet";
import { home_meta_info } from "./data";
const TRACKING_ID = "G-J40JB9QM9P"; // Replace with your GA tracking ID
ReactGA.initialize(TRACKING_ID);

export default function App() {
  useLanguagePercistant();
  const dispatch = useDispatch();
  useEffect(() => {
    // Track page view
    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(fetchPlanHeader());
    dispatch(fetchHeaderMenu());
    dispatch(fetchConfigToolsInfo())
  }, []);
  const translate = useLanguageTranslate();

  return (
    <div className={translate("english", "arabic")}>
      <ReactHelmet meta_data={home_meta_info} />
      <Layout />
      <CustomToastContainer />

    </div>
  );
}
