export const home_meta_info = {
    meta_title: "Solidarity Bahrain",
    meta_description: "",
    meta_keywords: "",
}

export const saving_and_protection_meta = {
    meta_title: "Saving and Protection Plans",
    meta_description: "Explore our savings and protection plans designed to meet your needs.",
    meta_keywords: "savings, protection, insurance plans, financial security"
};