import { BASE_URL, CALCULATOR_BASE_URL, CRM_API_URL } from "./baseURL";
import { get, post } from "./api";

export const getCalculatorData = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { universityMind, plan, inflation, university } = payload;
    try {
      const res = await get(
        `${CALCULATOR_BASE_URL}university?Country=${university}&PlanTerm=${plan}&InflationRate=${inflation}&University=${universityMind}`
      );
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getCoverageCalculatorData = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { amount, currency, age, numberOfYears } = payload;
    try {
      const res = await get(
        `${CALCULATOR_BASE_URL}coverage?AnnualIncome=${amount}&Currency=${currency}&Age=${age}&NumberOfYears=${numberOfYears}`
      );
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  });
};


export const actionButtons = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(
        `${BASE_URL}/api/plan-type-config?populate=plan_step_sec,plan_step_sec.content,plan_step_sec.actions,plan_step_sec.steps.icon,faq_sec_info,faqs,faqs.question,faqs.answer,hero_action,plan_actions,faq_sub_sec_question,faq_actions,bottom_banner.banner.img,bottom_banner.content,bottom_banner.action`
      );
      resolve(res?.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const planDetails=(payload)=>{
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(
        `${BASE_URL}/api/plans?populate=banner,seo_meta,banner.img,banner.arabic_img,key_feat,key_feat.icon,content,faqs.question,faqs.answer,plan_step_sec,plan_step_sec,plan_step_sec.actions.plan_step_sec.steps,plan_step_sec.steps.icon,plan_step_sec.content&filters[slug][$eq]=${payload}`
      );
      resolve(res?.data);
    } catch (err) {
      reject(err);
    }
  });

}

export const planDetailsConfig=(payload)=>{
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(
        `${BASE_URL}/api/plan-config?populate=*`
      );
      resolve(res?.data);
    } catch (err) {
      reject(err);
    }
  });

}

export const getSocialLinks = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(
        `${BASE_URL}/api/utils?populate[0]=social_icon.social_img`
      );
      resolve(res?.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getPlansProtection = (payload) => {

  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(
        `${BASE_URL}/api/plan-types?populate=banner,seo_meta,banner.img,banner.arabic_img,content,plans,plans,plans.banner.img,plans.content,plans.key_feat.icon,faqs,faqs.question,faqs.answer,plans.listing_titles&filters[slug][$eq]=${payload}`
      );
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getPageNotFound = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(
        `${BASE_URL}/api/not-found?populate[0]=notfound&populate[1]=background.img&populate[2]=seo_meta`
      );
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  });
};


export const getHeaderRoutes=(payload)=>{
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(`${BASE_URL}/api/plan-types?populate=content,plans,plans,plans.content,header_menu_icon.img&sort=order`);
      resolve(res?.data);
    } catch (err) {
      reject(err);
    }
  });
}

export const getSpeakToAdvisor = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(
        `${BASE_URL}/api/speak-to-advisor?populate[0]=title,name,email,number,submitbutton&populate[1]=thanks.icon,thanksbutton`
      );
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  });
};
export const setCRMLead = (payload) => {
  const fullName = payload.name ? payload.name.trim() : '';
  const nameParts = fullName.split(' ');

  const FirstName = nameParts.length > 0 ? nameParts[0] : '';
  const LastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : ''; 
  const copPayload = {
      "FirstName": FirstName,
      "LastName": LastName,
      "CPR": payload.CPR ? payload.CPR :'',
      "PhoneNumber": payload.mobile ? payload.mobile :'',
      "subject": payload.subject ||"",
      "Product": "LIFE",
      "Email": payload.email ? payload.email :'',
      "customerType": 1,
      "status": 1,
      "productType": payload.productType ||"",
      "stage": "",
      "lead_id": "",
      "Quote": "",
      "channel": "online.solidarity",
      "LOBProduct": payload.LOBProduct||""
  }
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(CRM_API_URL, copPayload);
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getMenuDropdowns = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(
        `${BASE_URL}/api/parent-menus?populate=menu_groups.menu_group.icon.img,menu_groups.menu_group.menu.icon,label&sort=order`
      );
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  });
};
