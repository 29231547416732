import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from '../axiosInstance'
import { apiEndPoints } from '../../api/apiEndpoints'

export const CalculatorStoreSelector = "calculator"

const initialState = {
    coverage_calc_data: null,
    child_edu_data: null,
    isLoading: false,
    error: null
}

export const fetchChildCalculator = createAsyncThunk("fetch_child_calculator", (queryString, { rejectWithValue }) => {
    return Axios.get(`${apiEndPoints.childCalculator}`).then(res => res.data);
})
export const fetchCoverageCalculator = createAsyncThunk("fetch_coverage_calculator", (queryString, { rejectWithValue }) => {
    return Axios.get(`${apiEndPoints.coverageCalculator}`).then(res => res.data);
})

export const calculatorSlice = createSlice({
    name: CalculatorStoreSelector,
    initialState,
    reducers: {
        clearCalculatorError: (state) => {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        // CHILD CALCULATOR
        builder.addCase(fetchChildCalculator.pending, (state, action) => {
            if (!state.child_edu_data) {
                state.isLoading = true
            }
        })
        builder.addCase(fetchChildCalculator.fulfilled, (state, action) => {
            state.isLoading = false
            state.child_edu_data = action.payload?.data
        })
        builder.addCase(fetchChildCalculator.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error?.message
        })

        // COVERAGE CALCULATOR
        builder.addCase(fetchCoverageCalculator.pending, (state, action) => {
            if (!state.coverage_calc_data) {
                state.isLoading = true
            }
        })
        builder.addCase(fetchCoverageCalculator.fulfilled, (state, action) => {
            state.isLoading = false
            state.coverage_calc_data = action.payload?.data
        })
        builder.addCase(fetchCoverageCalculator.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error?.message
        })




    }
})

// SELECTORS
export const selectCalculatorWhole = (store) => store[CalculatorStoreSelector];
export const selectChildCalculator = (store) => store[CalculatorStoreSelector]?.child_edu_data;
export const selectCoverageCalculator = (store) => store[CalculatorStoreSelector]?.coverage_calc_data;
export const selectCalculatorLoader = (store) => store[CalculatorStoreSelector]?.isLoading;

// ACTIONS TO DISPATCH
export const { clearCalculatorError } = calculatorSlice.actions

export default calculatorSlice.reducer