import React, { Component } from "react";
import ContentLoader from "react-content-loader";

export default class ProgressBar extends Component {
  render() {
    const isRTL = document.body.getAttribute("dir") === "rtl";
    return (
      <>
        <div
          role="progressbar"
          className="absolute top-0 left-0 w-full z-[199999]"
        >
          <div className="h-0.5 bg-blue-500 animate-pulse"></div>
        </div>
        <ContentLoader
          speed={2}
          style={{ marginBottom: "4px" }}
          rtl={isRTL}
        ></ContentLoader>
      </>
    );
  }
}
