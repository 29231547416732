import { protection_plans_slug, s_and_p_plan_slug } from "../utils/slugs";

export const apiEndPoints = {
  tools: "/api/tool",
  home: "/api/home?populate[hero_sec][populate]=content,actions,banner,banner.img,banner.arabic_img&populate[content_sec_1][populate]=content,services,actions,services.icon&populate[plans][populate]=content,content.icon,banner,banner.img&populate[banner_sec][populate]=content,action,banner,banner.img&populate[plan_sec][populate]=content,actions,steps,steps.icon&populate[testimonial_sec][populate]=testimonials,testimonials.user_img,content&populate[bog_sec][populate]=content,action&populate[seo_meta]=*",
  home_plans: `/api/plan-types?populate=plans,plans,plans.banner.img,banner.arabic_img,plans.slider_titles&filters[slug][$eq]=${s_and_p_plan_slug}&filters[slug][$eq]=${protection_plans_slug}`,
  //getHeaderRoutes
  plansHeaderRoutes:
    "/api/plan-types?populate=content,plans,plans,plans.content,header_menu_icon.img&sort=order",
  // actionButtons
  planTypeConfig:
    "/api/plan-type-config?populate=plan_step_sec,plan_step_sec.content,plan_step_sec.actions,plan_step_sec.steps.icon,faq_sec_info,faqs,faqs.question,faqs.answer,hero_action,plan_actions,faq_sub_sec_question,faq_actions,bottom_banner.banner.img,bottom_banner.content,bottom_banner.action",
  // getPlansProtection
  planTypeWithPlans:
    "/api/plan-types?populate=banner,banner.img,content,plans,plans,plans.banner.img,plans.content,plans.key_feat.icon,faqs,faqs.question,faqs.answer&filters[slug][$eq]=",
  //planDetails
  planDetail:
    "/api/plans?populate=banner,banner.img,banner.arabic_img,key_feat,key_feat.icon,content,faqs.question,faqs.answer,plan_step_sec,plan_step_sec,plan_step_sec.actions.plan_step_sec.steps,plan_step_sec.steps.icon,plan_step_sec.content&filters[slug][$eq]=",
  // planDetailsConfig
  planDetailConfig: "/api/plan-config?populate=*",
  getArticlePgeStaticData:
    "/api/learning-center/?populate[0]=latest_article.short_description,latest_article.title,latest_article.description,latest_article.button&populate[1]=cta.banner.img,cta.content,cta.actions&populate[2]=blog_category_detail_btn&populate[3]=services_section.content,services_section.services.icon,services_section.actions",
  getLearningCenterHomePageData:
    "/api/learning-center/?populate[0]=hero.banner.img,banner.arabic_img,hero.content,hero.actions&populate[1]=services_section.content,services_section.services.icon,services_section.actions&populate[3]=latest_article.short_description,latest_article.title,latest_article.description,latest_article.button&populate[4]=cta.banner.img,cta.content,cta.actions&populate[5]=breadcrumb&populate[6]=blog_category_detail_btn&populate[7]=seo_meta",
  getLatestArticles: ({ category, count }) =>
    `/api/learning-articles?populate[0]=blog_image.img&populate[1]=blog_title&populate[2]=tags&populate[3]=categories&populate[4]=publish_date&populate[5]=short_description&populate[6]=authors.author_name,authors.avatar.img&populate[7]=content_section.content,content_section.section_image.img,content_section.blockquote,content_section.section_title&sort[publish_date]=desc${
      count ? `&pagination[limit]=${count}` : ""
    }${
      category
        ? `&filters[categories][slug][$eq]=${encodeURIComponent(category)}`
        : ""
    }`,
  getSingleArticle: (slug) =>
    `/api/learning-articles?populate[0]=blog_image.img&populate[1]=blog_title&populate[2]=tags&populate[3]=categories&populate[4]=publish_date&populate[5]=content_section.content,content_section.section_image.img,content_section.blockquote,content_section.section_title&populate[6]=short_description&populate[7]=authors.author_name,authors.avatar.img&populate[8]=seo_meta&filters[slug][$eq]=${slug}`,
  getLearningCenterCategoryStaticData: (category, count) =>
    `/api/learning-article-categories?populate=seo_meta,category_page_title,breadcrumb,learning_center_listing_titles,learning_articles.blog_title,learning_articles.short_description,learning_articles.blog_image.img,learning_articles.content_section,learning_articles.tag,learning_articles.authors.author_name,learning_articles.authors.avatar.img${
      category ? `&filters[slug][$eq]=${encodeURIComponent(category)}` : ""
    }${count ? `&pagination[limit]=${count}` : ""}`,

  getSocialLinks: "/api/utils?populate[0]=social_icon.social_img",
  pageNotFound: "",
  planTypesHeader: "",
  parentMenusHeaderAndFooter:
    "/api/parent-menus?populate=menu_groups.menu_group.icon.img,menu_groups.menu_group.menu.icon,label&sort=order",
  makeAclaim:
    "/api/make-a-claim?populate[0]=hero.image.img,hero.subtitle,hero.title,hero.description,hero.callus,hero.mobile,hero.email,hero.emailadd&populate[1]=faq.qna,faq.faq_heading,faq.button,faq.button_title&populate[2]=seo_meta",

  speakToAdvisor: "",
  childCalculator:
    "/api/child-edu-goal?populate=seo_meta,headings,name_input_label,email_input_label,mobile_input_label,university_menu,university_in_mind_input_label,inflation_menu,action_btn,result_action_btns,result_disclaimer_text,plan_term_input_label,inflation_input_label,select_university_input_label,plan_term_menu,banner.img",
  coverageCalculator:
    "/api/coverage-calculator?populate=seo_meta,headings,name_input_label,email_input_label,mobile_input_label,d_o_b_input_label,cpr_label,currency_label,annual_income_label,no_of_years_label,currency_menu,action_btn,result_action_btns,result_disclaimer_text,banner.img",
};
