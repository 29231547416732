import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../axiosInstance";
import { apiEndPoints } from "../../api/apiEndpoints";

export const MakeAClaimStoreSelector = "make_a_claim";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const fetchMakeAClaim = createAsyncThunk(
  "fetch_make_a_claim",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`${apiEndPoints.makeAclaim}`).then((res) => res.data);
  }
);

export const makeAClaimSlice = createSlice({
  name: MakeAClaimStoreSelector,
  initialState,
  reducers: {
    clearMakeAClaimError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // FETCH  MAKE CLAIM DATA
    builder.addCase(fetchMakeAClaim.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchMakeAClaim.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchMakeAClaim.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });
  },
});

// SELECTORS
export const selectMakeAClaimWhole = (store) => store[MakeAClaimStoreSelector];
export const selectMakeAClaimData = (store) =>
  store[MakeAClaimStoreSelector]?.data;
export const selectMakeAClaimLoader = (store) =>
  store[MakeAClaimStoreSelector]?.isLoading;

// ACTIONS TO DISPATCH
export const { clearMakeAClaimError } = makeAClaimSlice.actions;

export default makeAClaimSlice.reducer;
