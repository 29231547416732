import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import logo from "../../assets/images/Logo.svg";
import { useTranslation } from "react-i18next";
import DropdownMenu from "./dropdownMenu";
import { setLanguageInLocalStorage } from "../../hooks/useLanguagePercistant";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import SpeakAdvisorModal from "../../pages/preLogin/modal/speakAdvisorModal";
import { useDispatch, useSelector } from "react-redux";
import { selectPlanHeader } from "../../redux/features/plansSlice";
import { selectHeaderMenu } from "../../redux/features/toolsSlice";

import {
  general_insurance_slug,
  life_insurance_slug,
  for_businesses_slug,
  claims_slug,
  support_slug,
  login_and_quote,
  login_action,
  quote_action,
} from "../../utils/slugs";
import { setHamburger } from "../../redux/features/homeSlice";
import { useLocation } from "react-router-dom";
const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const headerdata = useSelector(selectPlanHeader);
  const headermenu = useSelector(selectHeaderMenu);
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const [isNavbarActive, setIsNavbarActive] = useState(false);
  const translate = useLanguageTranslate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1295);
  const [generalInsuranceDataisOpen, setGeneralInsuranceDataisOpen] =
    useState(false);
  const [lifeInsuranceDataisOpen, setLifeInsuranceDataisOpen] = useState(false);
  const [claimsDataisOpen, setClaimsDataisOpen] = useState(false);
  const [supportDataisOpen, setSupportDataisOpen] = useState(false);
  const [menuDataDropdown, setMenuDataDropdown] = useState(null);
  const [routes, setRoutes] = useState(null);
  const generalInsuranceRef = useRef(null);
  const lifeInsuranceRef = useRef(null);
  const claimsRef = useRef(null);
  const supportRef = useRef(null);
  const dropdownClickedRef = useRef(false);
  const [isSpeakToAdvisor, setIsSpeakToAdvisor] = useState(false);
  const [headerBtns, setHeaderBtns] = useState([]);
  const [isGeneralInsuranceActive, setIsGeneralInsuranceActive] =
    useState(false);
  const [isLifeInsuranceActive, setIsLifeInsuranceActive] = useState(false);
  const [isSupportActive, setIsSupportActive] = useState(false);
  const [isClaimActive, setIsClaimActive] = useState(false);

  const toggleModal = () => {
    setIsSpeakToAdvisor(!isSpeakToAdvisor);
  };

  const drodownItemsFormatter = (data) => {
    let transformedData = data.map((item) => ({
      category: {
        name: {
          en: item.attributes.menu_group.category_en,
          ar: item.attributes.menu_group.category_ar,
        },
        icon: item?.attributes?.menu_group?.icon,
      },
      items: item.attributes.menu_group.menu?.map((menuItem) => ({
        name: {
          en: menuItem?.label_en,
          ar: menuItem?.label_ar,
        },
        icon: {
          data: menuItem?.icon?.data?.attributes?.url,
          img_alt_en: menuItem?.icon_alt_en || "",
          img_alt_ar: menuItem?.icon_alt_ar || "",
        },
        link: menuItem?.link,
        isInternal: menuItem?.is_internal,
        is_hide_in_header: menuItem?.is_hide_in_header,
        custom_action: menuItem?.custom_action,
      })),
    }));
    return transformedData;
  };

  const seperateDropdownData = (data) => {
    let result = {
      generalInsurance: null,
      lifeInsurance: null,
      forBusiness: null,
      claims: null,
      support: null,
    };

    data.map((option) => {
      const { slug, label, menu_groups } = option.attributes;
      switch (slug) {
        case general_insurance_slug:
          result.generalInsurance = {
            label: label,
            menu: drodownItemsFormatter(menu_groups.data),
          };
          break;
        case life_insurance_slug:
          result.lifeInsurance = {
            label: label,
            menu: drodownItemsFormatter(menu_groups.data),
          };
          break;
        case for_businesses_slug:
          result.forBusiness = {
            label: label,
            menu: drodownItemsFormatter(menu_groups.data),
          };
          break;
        case claims_slug:
          result.claims = {
            label: label,
            menu: drodownItemsFormatter(menu_groups.data),
          };
          break;
        case support_slug:
          result.support = {
            label: label,
            menu: drodownItemsFormatter(menu_groups.data),
          };
          break;
        case login_and_quote:
          setHeaderBtns(
            menu_groups?.data?.map(
              (btnArray) => btnArray.attributes.menu_group.menu
            )
          );
          break;
        default:
          break;
      }
      return null;
    });

    return result;
  };



  const toggleDropdown = (dropdownType) => {
    dropdownClickedRef.current = true;
    switch (dropdownType) {
      case "generalInsurance":
        setGeneralInsuranceDataisOpen((prev) => !prev);
        setLifeInsuranceDataisOpen(false);
        setClaimsDataisOpen(false);
        setSupportDataisOpen(false);
        break;
      case "lifeInsurance":
        setLifeInsuranceDataisOpen(!lifeInsuranceDataisOpen);
        setGeneralInsuranceDataisOpen(false);
        setClaimsDataisOpen(false);
        setSupportDataisOpen(false);
        break;
      case "claims":
        setClaimsDataisOpen(!claimsDataisOpen);
        setGeneralInsuranceDataisOpen(false);
        setLifeInsuranceDataisOpen(false);
        setSupportDataisOpen(false);
        break;
      case "support":
        setSupportDataisOpen((prev) => !prev);
        setGeneralInsuranceDataisOpen(false);
        setLifeInsuranceDataisOpen(false);
        setClaimsDataisOpen(false);
        break;
      default:
        setSupportDataisOpen(false);
        setGeneralInsuranceDataisOpen(false);
        setLifeInsuranceDataisOpen(false);
        setClaimsDataisOpen(false);
        break;
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownClickedRef.current) {
      dropdownClickedRef.current = false;
      return;
    }

    if (
      generalInsuranceRef.current &&
      !generalInsuranceRef.current.contains(event.target)
    ) {
      setGeneralInsuranceDataisOpen(false);
    }
    if (
      lifeInsuranceRef.current &&
      !lifeInsuranceRef.current.contains(event.target)
    ) {
      setLifeInsuranceDataisOpen(false);
    }
    if (claimsRef.current && !claimsRef.current.contains(event.target)) {
      setClaimsDataisOpen(false);
    }
    if (supportRef.current && !supportRef.current.contains(event.target)) {
      setSupportDataisOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const formatter = (data) => {
    let result = data.map((each, index) => {
      let category = each.attributes;
      let slug = category.slug;

      let submenu = category.plans?.data?.map((plan) => {
        let format = {
          name: {
            en: plan.attributes.content.subtitle_en,
            ar: plan.attributes.content.subtitle_ar,
          },
          icon: null,
          link: `/product/${slug}/${plan.attributes.slug}`,
          // link:"/home",
          isInternal: true,
        };

        return format;
      });
      let overview = {
        name: { en: "Overview", ar: "نظرة عامة" },
        icon: null,
        link: `/product/${slug}`,
        // link:"/home",
        isInternal: true,
      };
      let formatted_data = {
        category: {
          name: {
            en: category.content.subtitle_en,
            ar: category.content.subtitle_ar,
          },
          icon: category.header_menu_icon,
        },
        items: [overview, ...submenu],
      };
      return formatted_data;
    });
    return result;
  };
  useEffect(() => {
    if (headerdata) {
      const data = formatter(headerdata);
      setRoutes(data);
    }
  }, [headerdata]);
  useEffect(() => {
    if (headermenu) {
      const data = seperateDropdownData(headermenu);
      setMenuDataDropdown(data);
    }
  }, [headermenu]);

  const navbarMenuRef = useRef(null);
  const burgerMenuRef = useRef(null);
  const { i18n } = useTranslation();

  document.body.dir = i18n.dir();

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1295);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const toggleNavbar = () => {
      setIsNavbarActive(!isNavbarActive);
    };

    if (burgerMenuRef.current) {
      burgerMenuRef.current.addEventListener("click", toggleNavbar);
    }

    return () => {
      if (burgerMenuRef.current) {
        burgerMenuRef.current.removeEventListener("click", toggleNavbar);
      }
    };
  }, [isNavbarActive, headermenu]);

  const handleLanguageChange = () => {
    let switch_lang = i18n.resolvedLanguage === "ar" ? "en" : "ar";
    i18n.changeLanguage(switch_lang);
    setLanguageInLocalStorage(switch_lang);
  };

  const closeHamburgerMenu = () => {
    setIsNavbarActive(false);
    setIsBurgerActive(false);
  };

  useEffect(() => {
    dispatch(setHamburger(isBurgerActive));
  }, [isBurgerActive]);

  const isActiveRoute = (link) => location.pathname === link;

  const isRouteActive = (menu) => {
    return menu?.some((item) =>
      item.items?.some((subItem) => isActiveRoute(subItem.link))
    );
  };

  const checkAndSetActive = (menu, setActive) => {
    if (menu?.length > 0) {
      setActive(isRouteActive(menu));
    }
  };

  useEffect(() => {
    const { generalInsurance, lifeInsurance, support, claims } =
      menuDataDropdown || {};

    checkAndSetActive(generalInsurance?.menu, setIsGeneralInsuranceActive);

    if (lifeInsurance?.menu?.length > 0 || routes?.length > 0) {
      const finalData = [...(lifeInsurance.menu || []), ...(routes || [])];
      checkAndSetActive(finalData, setIsLifeInsuranceActive);
    }

    checkAndSetActive(support?.menu, setIsSupportActive);
    checkAndSetActive(claims?.menu, setIsClaimActive);
  }, [location]);

  if (!headermenu || headermenu.length === 0) {
    return null; // Do not render the header if headermenu is null or empty
  }

  return (

        <div className={`flex header-banner`}>
          <header className="header" id="header">
            <div className="inner grid lg:grid-cols-8 xl:grid-cols-8 gap-4">
              <div className="logoPanel lg:col-span-1 xl:col-span-1">
                <a href="/" className="brand">
                  <img src={logo} alt="logo" data-testid="logo" />
                </a>
              </div>
              <div className="menuPanel lg:col-span-4 xl:col-span-5">
                <div
                  className="burger"
                  id="burger"
                  ref={burgerMenuRef}
                  onClick={() => setIsBurgerActive(!isBurgerActive)}
                >
                  <span
                    data-testid="burger-menu"
                    className={`burger-line ${isBurgerActive ? "active" : ""}`}
                  ></span>
                  <span
                    className={`burger-line ${isBurgerActive ? "active" : ""}`}
                  ></span>
                  <span
                    className={`burger-line ${isBurgerActive ? "active" : ""}`}
                  ></span>
                </div>

                <nav className="navbar">
                  <div
                    className={`navbar-block grid grid-cols-1 md:grid-cols-1 gap-6 xs:mt-4 ${
                      isNavbarActive ? "is-active" : ""
                    }`}
                    ref={navbarMenuRef}
                    id="menu"
                  >
                    <ul
                      className={`menu order-2 items-center`}
                      ref={navbarMenuRef}
                    >
                      <li className="menu-item">
                        <button
                          type="button"
                          className={`inline-flex justify-between w-full  px-4 py-2  text-sm font-medium text-700 ${
                            isGeneralInsuranceActive ? "nav-active" : ""
                          }`}
                          id="options-menu"
                          aria-expanded={generalInsuranceDataisOpen}
                          aria-haspopup="true"
                          onClick={() => toggleDropdown("generalInsurance")}
                          data-testid="general-insurance-button"
                        >
                          {translate(
                            menuDataDropdown?.generalInsurance?.label?.title_en,
                            menuDataDropdown?.generalInsurance?.label?.title_ar
                          )}
                          <svg
                            className={`ml-2 h-5 w-5 transform transition-transform duration-300 ${
                              generalInsuranceDataisOpen ? "rotate-180" : ""
                            }`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 10 6"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              d="M1.5575 0.442383L5 3.87738L8.4425 0.442383L9.5 1.49988L5 5.99988L0.5 1.49988L1.5575 0.442383Z"
                              fill="#252C32"
                            />
                          </svg>
                        </button>

                        <DropdownMenu
                          isOpen={generalInsuranceDataisOpen}
                          toggleDropdown={() =>
                            toggleDropdown("generalInsurance")
                          }
                          data={menuDataDropdown?.generalInsurance?.menu}
                          className="GeneralInsurance-dropdown"
                          ref={generalInsuranceRef}
                          closeHamburgerMenu={closeHamburgerMenu}
                        />
                      </li>
                      <li className="menu-item">
                        <button
                          type="button"
                          className={`inline-flex justify-between w-full  px-4 py-2  text-sm font-medium text-700 ${
                            isLifeInsuranceActive ? "nav-active" : ""
                          } `}
                          id="options-menu"
                          aria-expanded={lifeInsuranceDataisOpen}
                          aria-haspopup="true"
                          onClick={() => toggleDropdown("lifeInsurance")}
                          data-testid="life-insurance-button"
                        >
                          {translate(
                            menuDataDropdown?.lifeInsurance?.label?.title_en,
                            menuDataDropdown?.lifeInsurance?.label?.title_ar
                          )}
                          <svg
                            className={`ml-2 h-5 w-5 transform transition-transform duration-300 ${
                              lifeInsuranceDataisOpen ? "rotate-180" : ""
                            }`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 10 6"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              d="M1.5575 0.442383L5 3.87738L8.4425 0.442383L9.5 1.49988L5 5.99988L0.5 1.49988L1.5575 0.442383Z"
                              fill="#252C32"
                            />
                          </svg>
                        </button>

                        <DropdownMenu
                          isOpen={lifeInsuranceDataisOpen}
                          toggleDropdown={() => toggleDropdown("lifeInsurance")}
                          data={[
                            ...(menuDataDropdown?.lifeInsurance?.menu || []),
                            ...(routes || []),
                          ]}
                          className="life-Insurance-drop-down"
                          ref={lifeInsuranceRef}
                          closeHamburgerMenu={closeHamburgerMenu}
                        />
                      </li>
                      <li className="menu-item dropdown postion-line">
                        <a
                          href="https://www.solidarity.com.bh/forbusinesses"
                          className=" menu-link vertical-gray-line"
                          data-testid="for-businesses-link"
                        >
                          {translate(
                            menuDataDropdown?.forBusiness?.label?.title_en,
                            menuDataDropdown?.forBusiness?.label?.title_ar
                          )}{" "}
                          <span>&nbsp;</span>
                        </a>
                      </li>

                      <li className="menu-item claim">
                        <button
                          type="button"
                          className={`inline-flex justify-between items-center w-full  px-4 py-2  text-sm font-medium text-700 claim-menu-title ${
                            isClaimActive ? "nav-active" : ""
                          }`}
                          id="options-menu"
                          aria-expanded={claimsDataisOpen}
                          aria-haspopup="true"
                          onClick={() => toggleDropdown("claims")}
                          data-testid="claims-button"
                        >
                          {translate(
                            menuDataDropdown?.claims?.label?.title_en,
                            menuDataDropdown?.claims?.label?.title_ar
                          )}
                          <svg
                            className={`ml-2 h-5 w-5 transform transition-transform duration-300 ${
                              claimsDataisOpen ? "rotate-180" : ""
                            }`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 10 6"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              d="M1.5575 0.442383L5 3.87738L8.4425 0.442383L9.5 1.49988L5 5.99988L0.5 1.49988L1.5575 0.442383Z"
                              fill="#252C32"
                            />
                          </svg>
                        </button>

                        <DropdownMenu
                          isOpen={claimsDataisOpen}
                          toggleDropdown={() => toggleDropdown("claims")}
                          data={menuDataDropdown?.claims?.menu}
                          className="claim-dropdown absolute left-1/2 z-10 w-72"
                          ref={claimsRef}
                          closeHamburgerMenu={closeHamburgerMenu}
                        />
                      </li>
                      <li className="menu-item support">
                        <button
                          type="button"
                          className={`inline-flex justify-between items-center w-full  px-4 py-2  text-sm font-medium claim-menu-title ${
                            isSupportActive ? "nav-active" : ""
                          }`}
                          id="options-menu"
                          aria-expanded={supportDataisOpen}
                          aria-haspopup="true"
                          onClick={() => {
                            toggleDropdown("support");
                          }}
                          data-testid="support-button"
                        >
                          {translate(
                            menuDataDropdown?.support?.label?.title_en,
                            menuDataDropdown?.support?.label?.title_ar
                          )}
                          <svg
                            className={`ml-2 h-5 w-5 transform transition-transform duration-300 ${
                              supportDataisOpen ? "rotate-180" : ""
                            }`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 10 6"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              d="M1.5575 0.442383L5 3.87738L8.4425 0.442383L9.5 1.49988L5 5.99988L0.5 1.49988L1.5575 0.442383Z"
                              fill="#252C32"
                            />
                          </svg>
                        </button>

                        <DropdownMenu
                          isOpen={supportDataisOpen}
                          toggleDropdown={() => toggleDropdown("support")}
                          data={menuDataDropdown?.support?.menu}
                          className="claim-dropdown absolute left-2/3 z-10 w-72"
                          ref={supportRef}
                          toggleModal={toggleModal}
                          closeHamburgerMenu={closeHamburgerMenu}
                        />
                      </li>
                    </ul>
                    <div className="userPanel mobile flex justify-between flex space-x-4">
                      {headerBtns[0]?.length > 0 &&
                        headerBtns[0]?.map((btn) => {
                          if (btn.custom_action === login_action) {
                            return (
                              <a
                                key={btn.id}
                                href={btn?.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn sign-in"
                                data-testid="sign-in-button"
                              >
                                {translate(btn.label_en, btn.label_ar)}
                              </a>
                            );
                          } else if (btn.custom_action === quote_action) {
                            return (
                              <a
                                key={btn.id}
                                href={btn?.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn get-quote"
                                data-testid="get-quote-link"
                              >
                                {translate(btn.label_en, btn.label_ar)}
                              </a>
                            );
                          } else {
                            return null;
                          }
                        })}

                      <button
                        className="menu-language-btn"
                        key={i18n.resolvedLanguage}
                        type="submit"
                        onClick={handleLanguageChange}
                      >
                        {i18n.resolvedLanguage === "en" ? "العربية" : "English"}
                      </button>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="userPanel desktop lg:col-span-2 xl:col-span-2">
                {headerBtns[0]?.length > 0 &&
                  headerBtns[0]?.map((btn) => {
                    if (btn.custom_action === login_action) {
                      return (
                        <a
                          key={btn.id}
                          href={btn?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn sign-in"
                          data-testid="sign-in-button"
                        >
                          {translate(btn.label_en, btn.label_ar)}
                        </a>
                      );
                    } else if (btn.custom_action === quote_action) {
                      return (
                        <a
                          key={btn.id}
                          href={btn?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn get-quote"
                          data-testid="get-quote-link"
                        >
                          {translate(btn.label_en, btn.label_ar)}
                        </a>
                      );
                    } else {
                      return null;
                    }
                  })}

                <button
                  className="menu-link menu-language-btn"
                  key={i18n.resolvedLanguage}
                  type="submit"
                  onClick={handleLanguageChange}
                >
                  {translate("العربية", "English")}
                </button>
              </div>
            </div>
          </header>
          <SpeakAdvisorModal
            showModal={isSpeakToAdvisor}
            toggleModal={toggleModal}
          />
        </div>
  );
};
export default Header;
