import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../axiosInstance";
import { apiEndPoints } from "../../api/apiEndpoints";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const fetchLatestArticles = createAsyncThunk(
  "fetch_latest_articles_data",
  (queryString, { rejectWithValue }) => {
    return Axios.get(
      `${apiEndPoints.getLatestArticles(queryString || "")}`
    ).then((res) => res.data);
  }
);

export const LatestArticlesSlice = createSlice({
  name: "LatestArticles",
  initialState,
  reducers: {
    clearLCHomePageError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // FETCH HOME DATA API
    builder.addCase(fetchLatestArticles.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchLatestArticles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchLatestArticles.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });
  },
});

// SELECTORS
export const selectLatestArticlesWhole = (store) => store.latestArticles;
export const selectLatestArticles = (store) => store.latestArticles?.data;
export const selectLatestArticlesLoader = (store) =>
  store.latestArticles?.isLoading;

// ACTIONS TO DISPATCH
export const { clearLCHomePageError } = LatestArticlesSlice.actions;

export default LatestArticlesSlice.reducer;
