import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useLanguageTranslate from '../../../hooks/useLanguageTranslate';
import { getCurrentDateFormatted } from '../../../utils/constant';

const CoverageCalculatorResult = ({ buttons, disclaimer, coverageResultData, formData, handlePrimary, handleSecondary }) => {
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const currentDate = getCurrentDateFormatted();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  return (
    <div className="resultBox" data-testid="result-box">
      <div className="flex justify-between mb-4">
        <h2 className="text-lg font-bold" data-testid="prepared-for">
          {t('calculators.CoverageCalculatorResult.studyPreparedFor')}: {formData.name}
        </h2>
        <p className="text-sm text-gray-600" data-testid="current-date">
          {t('calculators.CoverageCalculatorResult.date')}: {currentDate}
        </p>
      </div>
      <p className="text-gray-800 mb-4 text-left" data-testid="illustration-text">
        {t('calculators.CoverageCalculatorResult.illustrationText')}
      </p>
      <div className="text-center mb-6">
        <p className="text-xl font-semibold" data-testid="years-label">
          {t('calculators.CoverageCalculatorResult.years')}
        </p>
        <p className="text-4xl font-bold text-gray-800" data-testid="number-of-years">
          {formData.numberOfYears}
        </p>
      </div>
      <div className="text-center mb-6">
        <p className="text-xl font-semibold" data-testid="coverage-amount-label">
          {t('calculators.CoverageCalculatorResult.coverageAmount')}
        </p>
        <p className="text-4xl font-bold text-gray-800" data-testid="coverage-amount">
          {formData.currency === 'USD' ? '$' : formData.currency }&nbsp;{coverageResultData && coverageResultData.length > 0 ? coverageResultData[0].incomeNeeded : 0}
        </p>
      </div>
      <div className="button-div flex" data-testid="button-div">
        {Array.isArray(buttons) && buttons.length > 0
          ? buttons
              .filter((button) => !button.is_hide)
              .map((button, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => {
                    if (button.button_type === 'secondary') {
                      handleSecondary && handleSecondary();
                    } else {
                      handlePrimary && handlePrimary();
                    }
                  }}
                  className={`${button.button_type}-button`}
                  data-testid={`button-${index}`}
                >
                  {translate(button.label_en, button.label_ar)}
                </button>
              ))
          : buttons &&
            !buttons.is_hide && (
              <button
                type="button"
                onClick={handlePrimary && handlePrimary}
                className={`${buttons.button_type}-button single-button`}
                data-testid="single-button"
              >
                {translate(buttons.label_en, buttons.label_ar)}
              </button>
            )}
      </div>
      <p className="text-xs text-gray-500 mt-4 text-center" data-testid="disclaimer">
        {translate(disclaimer.content_en, disclaimer.content_ar)}
      </p>
    </div>
  );
};

export default CoverageCalculatorResult;
