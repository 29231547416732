function FooterdataArabic() {
  return (
    <>
      <div className="flex footer-bottom-section">
        <h5>
          <a href="https://www.solidarity.com.bh/termsofuse">
          شروط الاستخدام
          </a>{" "}
          |
          <a href="https://www.solidarity.com.bh/privacyandsecurity">
            {" "}
            الخصوصية والأمان
          </a>{" "}
          |
          <a
            href="https://content.solidarity.bh/uploads/solidarity_fees_and_charges_493d5a6b2e.pdf"
            target="_blank"
          >
            {" "}
            الرسوم والأسعار
          </a>
        </h5>
        <h6>
         <span className="robotofont">© 2024</span> سوليدرتي البحرين | جميع الحقوق محفوظة | مرخصة من قبل مصرف البحرين المركزي كشركة تكافل محلية - الفئة الرابعة
        </h6>
      </div>
    </>
  );
}
export default FooterdataArabic;
