import React, { useEffect, useState, useCallback } from "react";
import Modal from "../../../components/common/modal/modal";
import { useTranslation } from "react-i18next";
import ConformationModal from "./conformationModal";
import "react-international-phone/style.css";
import { MuiPhone } from "./TelInputFinal";
import { getSpeakToAdvisor, setCRMLead } from "../../../api/apiActions";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import ProgressBar from "../../../progressBar";
import Loader from "../../../components/loader/loader";
import { showToast } from "../../../components/toastify/toastify";
import InputComponent from "../../../components/inputComponent";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3";
import {GOOGLE_RECAPTCHA} from "../../../api/baseURL";
import { useSelector } from "react-redux";
import { selectUtilsInfo } from "../../../redux/features/toolsSlice";

const SpeakAdvisorModal = ({ showModal, toggleModal ,crmProductName ,crmProductType }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [valueName, setValueName] = useState("");
  const [valueEmail, setValueEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [SpeakAdvisorData, setSpeakAdvisorData] = useState({
    email: null,
    name: null,
    number: null,
    submitbutton: null,
    thanks: null,
    thanksbutton: null,
    title: null,
  });
  const [reCaptchaVerified, setReCaptchaVerified] = useState(false);

  const confirmToggleModal = () => setOpen(!open);
  const translate = useLanguageTranslate();
  const {crm_common_product_name,crm_common_product_type} = useSelector(selectUtilsInfo)||{};

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length === 0 && reCaptchaVerified) {
        setIsSubmitDisabled(true);
        const payload = {
          name: valueName,
          email: valueEmail,
          mobile: phone.replace(/\D/g, ""),
          subject : "Speak to our Financial advisor",
          LOBProduct : crmProductName || crm_common_product_name,
          productType : crmProductType || crm_common_product_type || "LIFE"
        };
        setCRMLead(payload)
          .then((res) => {
            setIsSubmitDisabled(false);
            toggleModal();
            confirmToggleModal();
          })
          .catch((err) => {
            showToast("error", "internal server error!");
            setIsSubmitDisabled(false);
            console.log(err);
          });
      } else {
        setErrors(validationErrors);
      }
    },
    [valueName, valueEmail, phone, toggleModal, confirmToggleModal]
  );

  const validateForm = useCallback(() => {
    const errors = {};
    if (!valueName) {
      errors.valueName = t("validation.required");
    } else if (!/^[A-Za-z\s]+$/.test(valueName)) {
      errors.valueName = t("validation.invalidName");
    }
    if (!valueEmail) {
      errors.valueEmail = t("validation.required");
    } else if (!/\S+@\S+\.\S+/.test(valueEmail)) {
      errors.valueEmail = t("validation.invalidEmail");
    }
    if (!phone) {
      errors.phone = t("validation.required");
    } else if (phone.length !== 8) {
      errors.phone = t("validation.invalidMobile");
    }
    return errors;
  }, [valueName, valueEmail, phone, t]);

  const handleNameChange = useCallback(
    (e) => {
      const { value } = e.target;
      setValueName(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        valueName: !value
          ? t("validation.required")
          : !/^[A-Za-z\s]+$/.test(value)
          ? t("validation.invalidName")
          : null,
      }));
    },
    [t]
  );

  const handleEmailChange = useCallback(
    (e) => {
      setValueEmail(e.target.value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        valueEmail: !e.target.value
          ? t("validation.required")
          : !/\S+@\S+\.\S+/.test(e.target.value)
          ? t("validation.invalidEmail")
          : null,
      }));
    },
    [t]
  );

  const handlePhoneChange = useCallback((e) => {
    const { value } = e.target;
    const sanitizedValue = value.replace(/\D/g, '').slice(0, 8);
    setPhone(sanitizedValue);
    setErrors((prevErrors) => ({
      ...prevErrors,
      phone: sanitizedValue.length === 8 ? null : t("validation.invalidMobile")
    }));
  }, [t]);

  const getInitialData = async () => {
    const res = await getSpeakToAdvisor();
    setSpeakAdvisorData(res?.data?.attributes);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    setValueName("");
    setValueEmail("");
    setPhone("");
    setErrors({});
  }, [showModal]);

  if (!SpeakAdvisorData) {
    return <ProgressBar />;
  }

  const { email, name, number, submitbutton, thanks, thanksbutton, title } =
    SpeakAdvisorData;

  return (
    <div >
      <Modal
        role="dialog"
        aria-modal="true"
        show={showModal}
        onClose={toggleModal}
        title={translate(title?.title_en, title?.title_ar)}
      >
        <div >
          <div className="bg-white modal">
            <div className="order-1">
              <h2 className="mb-6 text-auto max-w-max">
                {translate(title?.description_en, title?.description_ar)}
              </h2>
              <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA}>
              <form className="space-y-4" onSubmit={handleSubmit}>
                <InputComponent
                  label={translate(name?.title_en, name?.title_ar)}
                  name={name}
                  value={valueName}
                  handleChange={handleNameChange}
                  error={errors.valueName}
                  type="text"
                  data-testid="name"
                />
                <InputComponent
                  label= {translate(email?.title_en, email?.title_ar)}
                  name={email}
                  value={valueEmail}
                  handleChange={handleEmailChange}
                  error={errors.valueEmail}
                   type="text"
                  data-testid="email"
                />
                <div className="formField">
                  <label className="block text-sm font-medium mb-1">
                    {translate(number?.title_en, number?.title_ar)}
                  </label>
                  <MuiPhone
                    onChange={handlePhoneChange}
                    value={phone}
                    data-testid="mobile"
                  />
                  {errors.phone && (
                    <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
                  )}
                </div>
                  <div className="recaptcha-container">
                    <GoogleReCaptcha onVerify={(token) => {
                        if (token) {
                          setReCaptchaVerified(true);
                        }
                    }} />
                  </div>
                <div className="buttonSet">
                  {!submitbutton?.is_hide && (
                    <button
                      type="submit"
                      data-testid="submit"
                      className={`w-full text-black p-2 rounded-lg ${submitbutton?.button_type}-button`}
                      disabled={isSubmitDisabled || !reCaptchaVerified}
                    >
                      {translate(
                        submitbutton?.label_en,
                        submitbutton?.label_ar
                      )}
                    </button>
                  )}
                </div>
              </form>
              </GoogleReCaptchaProvider>
            </div>
          </div>
        </div>
      </Modal>
      <ConformationModal
        showModal={open}
        toggleModal={confirmToggleModal}
        thanks={thanks}
        thanksbutton={thanksbutton}
      />
      <Loader show={isSubmitDisabled} />
    </div>
  );
};

export default SpeakAdvisorModal;
