import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCalculatorData, setCRMLead } from "../../../api/apiActions";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { MuiPhone } from "../modal/TelInputFinal";
import UniversityCostCalculator from "./childCalculatorResult";
import { BASE_URL } from "../../../api/baseURL";
import SpeakAdvisorModal from "../modal/speakAdvisorModal";
import ProgressBar from "../../../progressBar";
import Loader from "../../../components/loader/loader";
import { showToast } from "../../../components/toastify/toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchChildCalculator, selectChildCalculator } from "../../../redux/features/calculatorSlice";
import ReactHelmet from "../../../components/common/ReactHelmet/ReactHelmet";
import InputComponent from "../../../components/inputComponent"
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3";
import { GOOGLE_RECAPTCHA } from "../../../api/baseURL";
import { selectUtilsInfo } from "../../../redux/features/toolsSlice";

const ChildEducationCalculator = () => {
  const { i18n, t } = useTranslation();
  const translate = useLanguageTranslate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    university: "Bahrain",
    universityMind: "",
    plan: "5",
    inflation: "2.5",
  });
  const [errors, setErrors] = useState({});
  const [isResult, setIsResult] = useState(false);
  const [childCalculatorResult, setChildCalculatorResult] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isSpeakToAdvisor, setIsSpeakToAdvisor] = useState(false);
  const [reCaptchaVerified, setReCaptchaVerified] = useState(false);
  const [apiResponseData, setApiResponseData] = useState(null);


  const handleSecondary = () => {
    setErrors({})
    setFormData(prev => ({
      ...prev,
    }));
  };


  const toggleModal = () => {
    setIsSpeakToAdvisor(!isSpeakToAdvisor);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    // Ensure that mobile input accepts only digits
    if (name === 'mobile') {
      newValue = value.replace(/\D/g, '');  // Remove non-digit characters
      if (newValue.length > 8) {
        newValue = newValue.substring(0, 8);  // Limit to 8 digits
      }
    }

    setFormData(prev => ({ ...prev, [name]: newValue }));
    validateField(name, newValue);  // Call validation for the specific field
  };

  const validateField = (fieldName, value) => {
    let newError = '';

    switch (fieldName) {
      case 'name':
        if (!value) {
          newError = t("validation.required");
        } else if (!/^[A-Za-z\s]+$/.test(value)) {
          newError = t("validation.invalidName");
        }
        break;
      case 'email':
        if (!value) {
          newError = t("validation.required");
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          newError = t("validation.invalidEmail");
        }
        break;
      case 'mobile':
        if (!value) {
          newError = t("validation.required");
        } else if (!/^\d{8}$/.test(value)) {
          newError = t("validation.invalidMobile");
        }
        break;
      case 'university':
        if (!value) newError = t("validation.required");
        break;
      // case 'universityMind':
      //   if (!value) newError = t("validation.required");
      //   break;
      case 'plan':
        if (!value) newError = t("validation.required");
        break;
      case 'inflation':
        if (!value) newError = t("validation.required");
        break;
      default:
        break;
    }

    // Update the specific error field only
    setErrors(prevErrors => ({ ...prevErrors, [fieldName]: newError }));
  };


  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = t("validation.required");
    } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      newErrors.name = t("validation.invalidName");
    }
    if (!formData.email) {
      newErrors.email = t("validation.required");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t("validation.invalidEmail");
    }
    if (!formData.mobile) {
      newErrors.mobile = t("validation.required");
    } else if (!/^\d{8}$/.test(formData.mobile)) {
      newErrors.mobile = t("validation.invalidMobile");
    }
    if (!formData.university) {
      newErrors.university = t("validation.required");
    }
    // if (!formData.universityMind) {
    //   newErrors.universityMind = t("validation.required");
    // }
    if (!formData.plan) {
      newErrors.plan = t("validation.required");
    }
    if (!formData.inflation) {
      newErrors.inflation = t("validation.required");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const {crm_common_product_name,crm_common_product_type} = useSelector(selectUtilsInfo)||{};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm() && reCaptchaVerified) {
      setIsSubmitDisabled(true);
      const payload = {
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile.replace(/\D/g, ''),
        subject: "Child Eduction Goal",
        LOBProduct: crm_common_product_name,
        productType: crm_common_product_type || "LIFE"
      };

      try {
        const res = await getCalculatorData(formData);
        setChildCalculatorResult(res);
        setIsSubmitDisabled(false);
        setIsResult(!isResult)
        handleSecondary();
      } catch (err) {
        setIsSubmitDisabled(false);
        showToast("error", "internal server error");
      }

      try {
        const crmResponse = await setCRMLead(payload);
        setIsSubmitDisabled(false);
      } catch (err) {
        setIsSubmitDisabled(false);
        // showToast("error", "internal server error");
        return;
      }
      setIsSubmitDisabled(false);

    }
  };

  // const getInitialData = async () => {
  //   const response = await getChildCalculator();
  //   setApiResponseData(response.data.attributes);
  // };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isResult]);

  useEffect(() => {
    handleSecondary();
  }, [i18n.resolvedLanguage]);


  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchChildCalculator())
  }, []);
  const calculatorData = useSelector(selectChildCalculator);

  if (!calculatorData) {
    return <ProgressBar />;
  }

  const {
    name_input_label,
    headings,
    mobile_input_label,
    email_input_label,
    select_university_input_label,
    university_in_mind_input_label,
    plan_term_input_label,
    inflation_input_label,
    action_btn,
    result_action_btns,
    result_disclaimer_text,
    banner,
    university_menu,
    plan_term_menu,
    inflation_menu,
    seo_meta
  } = calculatorData?.attributes;

  const imageURL = `${BASE_URL}${banner?.img?.data?.attributes?.url}`;

  return (
    <>
      {seo_meta && <ReactHelmet meta_data={seo_meta} />}
      <div className="lg:p-10 calculatorBox child">
        <div className={`grid grid-cols-1 md:grid-cols-1 gap-6 p-6 xs:mt-4 bg-white customWrapper container`}>
          <div className={`order-2 md:order-2 md:col-span-1 lg:p-6 leftPanel`}>
            <h1 className="text-2xl font-bold mb-4 articleHead">
              {translate(headings?.title_en, headings?.title_ar)}
            </h1>
            <h2 className="mb-6 text-auto">
              {translate(headings?.description_en, headings?.description_ar)}
            </h2>

            {!isResult ? (
              <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA}>
                <form className="space-y-4" onSubmit={handleSubmit}>
                  <div>
                    <InputComponent
                      label={translate(name_input_label?.title_en, name_input_label?.title_ar)}
                      name="name"
                      value={formData.name}
                      handleChange={handleChange}
                      error={errors.name}
                      type="text"
                      data-testid="name"
                    />
                  </div>
                  <div>
                    <InputComponent
                      label={translate(email_input_label?.title_en, email_input_label?.title_ar)}
                      name="email"
                      value={formData.email}
                      handleChange={handleChange}
                      error={errors.email}
                      type="text"
                      data-testid="email"
                    />
                  </div>
                  <div className="mobileField">
                    <label htmlFor="mobile" className="block text-sm font-medium mb-1">
                      {translate(mobile_input_label?.title_en, mobile_input_label?.title_ar)}
                    </label>
                    <MuiPhone
                      name="mobile"
                      onChange={handleChange}
                      value={formData.mobile}
                      data-testid="mobile"
                    />
                    {errors.mobile && (
                      <p className="text-red-500 text-sm">{errors.mobile}</p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="university" className="block text-sm font-medium mb-1">
                      {translate(select_university_input_label?.title_en, select_university_input_label?.title_ar)}
                    </label>
                    <select
                      name="university"
                      value={formData.university}
                      onChange={handleChange}
                      data-testid="university"
                      className="w-full border border-gray-300 p-2 rounded-lg bg-white"
                    >
                      {university_menu &&
                        university_menu.map((university) => (
                          <option key={university.id} value={university.value}>
                            {translate(university.title_en, university.title_ar)}
                          </option>
                        ))}
                    </select>
                    {errors.university && (
                      <p className="text-red-500 text-sm">{errors.university}</p>
                    )}
                  </div>
                  {/* <div>
                    <InputComponent
                      label={translate(university_in_mind_input_label?.title_en, university_in_mind_input_label?.title_ar)}
                      name="universityMind"
                      value={formData.universityMind}
                      handleChange={handleChange}
                      error={errors.universityMind}
                      type="text"
                      data-testid="universityMind"
                    />
                  </div> */}
                  <div className="flex flex-col md:flex-row md:space-x-4 gap-6 numberField">
                    <div className="w-full md:w-1/2">
                      <label htmlFor="plan" className="block text-sm font-medium mb-1">
                        {translate(plan_term_input_label?.title_en, plan_term_input_label?.title_ar)}
                      </label>
                      <select
                        name="plan"
                        value={formData.plan}
                        onChange={handleChange}
                        data-testid="plan"
                        className="w-full border border-gray-300 p-2 rounded-lg bg-white"
                      >
                        {plan_term_menu && plan_term_menu.map((plan) => (
                          <option key={plan.id} value={plan.value}>
                            {translate(plan.title_en, plan.title_ar)}
                          </option>
                        ))}
                      </select>
                      {errors.plan && (
                        <p className="text-red-500 text-sm">{errors.plan}</p>
                      )}
                    </div>
                    <div className="w-full md:w-1/2 mt-4 md:mt-0">
                      <label htmlFor="inflation" className="block text-sm font-medium mb-1">
                        {translate(inflation_input_label?.title_en, inflation_input_label?.title_ar)}
                      </label>
                      <select
                        id="inflation"
                        name="inflation"
                        value={formData.inflation}
                        onChange={handleChange}
                        data-testid="inflation"
                        className="w-full border border-gray-300 p-2 rounded-lg bg-white"
                      >
                        {inflation_menu && inflation_menu.map((inflation) => (
                          <option key={inflation.id} value={inflation.value}>
                            {translate(inflation.title_en, inflation.title_ar)}
                          </option>
                        ))}
                      </select>
                      {errors.inflation && (
                        <p className="text-red-500 text-sm">{errors.inflation}</p>
                      )}
                    </div>
                  </div>
                  <div>
                    <GoogleReCaptcha onVerify={(token) => {
                      if (token) {
                        setReCaptchaVerified(true);
                      }
                    }} />
                  </div>
                  <div className="button-div">
                    <button
                      disabled={!reCaptchaVerified || isSubmitDisabled}
                      type="submit"
                      className="w-full primary-button md:w-auto bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    >
                      {translate(action_btn?.label_en, action_btn?.label_ar)}
                    </button>
                  </div>
                </form>
              </GoogleReCaptchaProvider>
            ) : (
              <UniversityCostCalculator
                buttons={result_action_btns}
                disclaimer={result_disclaimer_text}
                result={childCalculatorResult}
                handleSecondary={() => setIsResult(!isResult)}
                handlePrimary={toggleModal}
              />
            )}
          </div>
          <div className="order-1 md:order-1 md:col-span-1 rightPanel">
            <img
              src={imageURL}
              alt={translate(banner?.img_alt_en, banner?.img_alt_ar)}
            />
          </div>
        </div>
        <SpeakAdvisorModal showModal={isSpeakToAdvisor} toggleModal={toggleModal} />
        <Loader show={isSubmitDisabled} />
      </div>
    </>
  );
};

export default ChildEducationCalculator;
