import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Axios from '../axiosInstance';
import { apiEndPoints } from '../../api/apiEndpoints';

const initialState = {
    data: null,
    plansData: null,
    isLoading: false,
    plansIsLoading: false,
    error: null,
    plansError: null,
    isHamburger: false // initial state for hamburger menu
}

// Asynchronous thunks
export const fetchHomeData = createAsyncThunk("fetch_home_data", (queryString, { rejectWithValue }) =>
    Axios.get(`${apiEndPoints.home}${queryString || ""}`).then(res => res.data)
);
export const fetchHomePlansData = createAsyncThunk("fetch_home_plans_data", (queryString, { rejectWithValue }) =>
    Axios.get(`${apiEndPoints.home_plans}${queryString || ""}`).then(res => res.data)
);

// Slice definition
export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        clearHomePageError: state => {
            state.error = null;
        },
        clearHomePlansError: state => {
            state.plansError = null;
        },
        setHamburger: (state, action) => {
            state.isHamburger = action.payload; // Set hamburger menu state
        }
    },
    extraReducers: builder => {
        // Home data fetch
        builder.addCase(fetchHomeData.pending, state => {
            if (!state.data) state.isLoading = true;
        }).addCase(fetchHomeData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload?.data?.attributes;
        }).addCase(fetchHomeData.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error?.message;
        });

        // Home plans data fetch
        builder.addCase(fetchHomePlansData.pending, state => {
            if (!state.plansData) state.plansIsLoading = true;
        }).addCase(fetchHomePlansData.fulfilled, (state, action) => {
            state.plansIsLoading = false;
            state.plansData = action.payload?.data;
        }).addCase(fetchHomePlansData.rejected, (state, action) => {
            state.plansIsLoading = false;
            state.plansError = action.error?.message;
        });
    }
});

// Selectors
export const selectHomePageWhole = state => state.home;
export const selectHomePageData = state => state.home?.data;
export const selectHomePageLoader = state => state.home?.isLoading;
export const selectHomePlansPageData = state => state.home?.plansData;
export const selectHomePlansPageLoader = state => state.home?.plansIsLoading;
export const selectHamburgerState = state => state.home?.isHamburger; // Selector for isHamburger

// Actions
export const { clearHomePageError, clearHomePlansError, setHamburger } = homeSlice.actions;

export default homeSlice.reducer;
