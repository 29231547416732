import { useTranslation } from 'react-i18next';

const useLanguageTranslate = () => {
    const { i18n } = useTranslation();

    const translate= (en_value,ar_value)=>{
          let language = i18n.resolvedLanguage;
          if(language==="en") return en_value;
          return ar_value;
    }
  return translate
}

export default useLanguageTranslate;