import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showToast = (type, message, position = 'top-right') => {
  const options = {
    position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  switch (type) {
    case 'success':
      toast.success(message, options);
      break;
    case 'error':
      toast.error(message, options);
      break;
    case 'warn':
      toast.warn(message, options);
      break;
    default:
      toast.info(message, options);
      break;
  }
};

const CustomToastContainer = () => {
  return <ToastContainer />;
};

export default CustomToastContainer;
