import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../axiosInstance";
import { apiEndPoints } from "../../api/apiEndpoints";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const fetchSingleArticleData = createAsyncThunk(
  "fetch_single_data",
  (queryString, { rejectWithValue }) => {
    return Axios.get(
      `${apiEndPoints.getSingleArticle(queryString || "")}`
    ).then((res) => res.data);
  }
);

export const SingleArticleSlice = createSlice({
  name: "SingleArticle", //Learning Center category
  initialState,
  reducers: {
    clearSingleArticlePageError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // FETCH HOME DATA API
    builder.addCase(
      fetchSingleArticleData.pending,
      (state, action) => {
        if (!state.data) {
          state.isLoading = true;
        }
      }
    );
    builder.addCase(
      fetchSingleArticleData.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = action.payload?.data;
      }
    );
    builder.addCase(
      fetchSingleArticleData.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.error?.message;
      }
    );
  },
});

// SELECTORS
export const selectSingleArticlePageWhole = (store) =>
  store.singleArticle;
export const selectSingleArticlePageData = (store) =>
  store.singleArticle?.data;
export const selectSingleArticlePageLoader = (store) =>
  store.singleArticle?.isLoading;

// ACTIONS TO DISPATCH
export const { clearSingleArticlePageError } = SingleArticleSlice.actions;

export default SingleArticleSlice.reducer;
