import React, { useEffect, Suspense, lazy } from "react";
import { Routes, Route, useLocation ,HashRouter ,BrowserRouter} from "react-router-dom";
import Header from "../components/Headers/headers.jsx";
import Footer from "../components/footer/footer.jsx";
import ProgressBar from "../progressBar.jsx";
import NotFound from "../pages/preLogin/notFound/notFound.jsx";
import ChildEducationCalculator from "../pages/preLogin/calculators/childCalculators.jsx";
import CoverageCalculator from "../pages/preLogin/calculators/coverageCalculators.jsx";
import { selectHamburgerState } from "../redux/features/homeSlice.js";
import { useSelector } from "react-redux";

// Lazy load components
const Home = lazy(() => import("../pages/preLogin/home/home.jsx"));
const MakeAClaim = lazy(() => import("../pages/preLogin/makeAClaim/makeAClaim.jsx"));
const PlanLayout = lazy(() => import("../pages/preLogin/plansLayout/plansLayout.jsx"));
const AllPlans = lazy(() => import("../pages/preLogin/allPlans/allPlans.jsx"));
const LearningCenter = lazy(() => import("../pages/preLogin/learningCenter/learningCenter.jsx"));
const LearningCenterCategory = lazy(() => import("../pages/preLogin/learningCenterCategory/learningCenterCategory.jsx"));
const LearningCenterArticle = lazy(() => import("../pages/preLogin/learningCenterArticle/learningCenterArticle.jsx"));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Layout = () => {
  const isHamburger = useSelector(selectHamburgerState)

  return (
    <div className={`App ${isHamburger ? 'overflow-hidden ' : ''}`}>
      <BrowserRouter>
        <Suspense fallback={<ProgressBar />}>
        <ScrollToTop />
        <Header />
        {/* {isHamburger &&
        <div className="overlay-on-mobile"></div>} */}
          <div className="mainContainer">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/file-a-claim" element={<MakeAClaim />} />
              <Route path="/product/:plan_type_slug" element={<PlanLayout />} />
              <Route
                path="/product/:plan_type_slug/:slug"
                element={<AllPlans />}
              />
              <Route
                path="/learning-center/:category/:slug"
                element={<LearningCenterArticle />}
              />
              <Route path="/home" element={<Home />} />
              <Route path="/learning-center" element={<LearningCenter />} />
              <Route
                path="/learning-center/:category"
                element={<LearningCenterCategory />}
              />
              <Route
                path="/child-education-goal-calculator"
                element={<ChildEducationCalculator />}
              />
              <Route
                path="/insurance-coverage-calculator"
                element={<CoverageCalculator />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </Suspense>
      </BrowserRouter>
    </div>
  );
};

export default Layout;
