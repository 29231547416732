import React, { useState } from "react";
import useLanguageTranslate from "../hooks/useLanguageTranslate";

const InputComponent = ({
  label,
  name,
  value,
  handleChange,
  error,
  type = "text",
  placeHolder,
  ...rest
}) => {
  const translate = useLanguageTranslate();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className="formField form-input">
      <label className="block text-sm font-medium mb-1">{label && label}</label>
      <div className="relative w-full">
        <input
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`w-full p-2 border rounded-lg transition-colors duration-300 ${
            isFocused ? "border-active" : "border-default"
          }`}
          {...rest}
        />
        {placeHolder ? (
          <span
            className={`absolute inset-y-0 ${translate(
              "right-0",
              " left-0 ml-3",
            )} pr-3 flex items-center text-gray-400`}>
            {placeHolder && placeHolder}
          </span>
        ) : (
          ""
        )}
      </div>
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default InputComponent;
