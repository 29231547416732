import axios from "axios";
import { API_TOKEN, BASE_URL, DEV_API_TOKEN, DEV_BASE_URL } from "../api/baseURL";

const Axios = axios.create({
  baseURL: `${BASE_URL}`,
});

Axios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = API_TOKEN;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


export const DevAxios = axios.create({
  baseURL: `${DEV_BASE_URL}`,
});

DevAxios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = DEV_API_TOKEN;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



export default Axios;
