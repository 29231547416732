import { online_plans_slug, s_and_p_plan_slug } from "../../utils/slugs";

export const en = {
    "translation": {
      "language": "English",
      "header": {
        "signin": "Sign in",
        "generalInsurance": "General Insurance",
        "generalInsuranceMenu": {
          "1": "Basic"
        },
        "lifeInsurance": "Life Insurance",
        "lifeInsuranceMenu": {
          "1": "Basic"
        },
        "forBusiness": "For Business",
        "claims": "Claims",
        "support": "Support",
        "getQuote": "Get a Quote"
      },
      "calculators":{
        "ChildCalculator":{
         "title": "Child Education Goal",
         "description": "Tell us a bit about yourself. Your age, financial goals, and investment preferences help us tailor recommendations that are uniquely suited to your needs and aspirations.",
         "name":"What's your name?",
         "email":"What's your email?",
         "mobile":"What's your mobile number?",
         "university": "Where do you wish to send your child for University?",
         "universityMind": "Do you have a University in mind?",
         "plan":"Plan Term",
         "inflation":"Inflation Rate",
         "button":"Calculate"
       },
       "ChildCalculatorResult":{
        "averageTuitionFees": "Average Tuition Fees for 4 Years",
        "averageLivingCosts": "Average Cost of Living for 4 Years",
        "totalCostWithoutInflation": "Total Average University Education Cost at 0% Inflation",
        "totalCostWithInflation": "Total Average University Education Cost at {inflationRate}% Inflation"
      },
      "CoverageCalculator":{
        "title": "Coverage Calculator",
        "description": "Tell us a bit about yourself. Your age, financial goals, and investment preferences help us tailor recommendations that are uniquely suited to your needs and aspirations.",
        "name":"What's your name?",
        "email":"What's your email?",
        "mobile":"What's your mobile number?",
        "dateOfBirth": "Date of Birth",
        "CPRNumber": "What's you CRN Number?",
        "currency":"Currency",
        "howMuchAmount":"Annual Family Income",
        "noOfYearReplacement":"Number of Years replacement income is needed by your family",
        "button":"Calculate"
       },
       "CoverageCalculatorResult":{
        "studyPreparedFor": "Study Prepared for",
        "date": "Date",
        "illustrationText": "The below illustration explains how much Life Insurance is needed as a replacement income for your family.",
        "years": "Years",
        "coverageAmount": "Coverage Amount"
      }
      },
      "validation":{
        "required": "This field is required.",
        "invalidEmail":"Invalid email address.",
        "invalidMobile": "Invalid mobile number.",
        "invalidName": "Name can only contain alphabetic characters and spaces",
        "invalidNumber":"Please enter digits only.",
        "invalidCPR":"Please enter {{length}} digits only.",
        "futureDate":"Date of birth cannot be in the future"
      },
      "notFound":{
        "links":[
          {"text":"Home","href":"/"},
          {"text":"Life Insurance Plans","href":`/product/${s_and_p_plan_slug}`},
          {"text":"Buy Online","href":`/product/${online_plans_slug}`},
          {"text":"Child Calculator","href":"/child-education-goal-calculator"},
          {"text":"Coverage Calculator","href":"/insurance-coverage-calculator"},
          {"text":"Quick Pay","href":"/quick-pay"},
           {"text":"Site Map","href":"/site-map"}
        ]
      },
   }
}