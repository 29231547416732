import React, { useEffect } from "react";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { useTranslation } from "react-i18next";

const UniversityCostCalculator = ({
  buttons,
  disclaimer,
  result,
  handlePrimary,
  handleSecondary,
}) => {
  const translate = useLanguageTranslate();
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="resultBox mt-10">
      <div className="my-6 text-lg">
        <p>{t("calculators.ChildCalculatorResult.averageTuitionFees")}</p>
        <strong className="block text-2xl">
          ${result?.averageTutionFeesFor4Yrs}
        </strong>
      </div>
      <div className="my-6 text-lg">
        <p>{t("calculators.ChildCalculatorResult.averageLivingCosts")}</p>
        <strong className="block text-2xl">
          ${result?.averageCostOfLivingFor4Years}
        </strong>
      </div>
      <div className="my-6 text-lg">
        <p>
          {t("calculators.ChildCalculatorResult.totalCostWithoutInflation")}
        </p>
        <strong className="block text-2xl">
          ${result?.totalAvgUniversityEducationCostWithoutInflation}
        </strong>
      </div>
      <div className="my-6 text-lg">
        <p>
          {translate(
            `Total Average University Education Cost at ${result?.inflationRate}% Inflation`,
            `إجمالي متوسط ​​تكلفة التعليم الجامعي عند معدل تضخم يبلغ ${result?.inflationRate}%`
          )}
        </p>
        <strong className="block text-2xl">
          ${result?.totalAvgUniversityEducationCostWithInflation}
        </strong>
      </div>
      <div className="button-div">
        {Array.isArray(buttons) && buttons.length > 0
          ? buttons
              .filter((button) => !button.is_hide)
              .map(
                (button, index) =>
                  button.is_hide !== null && (
                    <button
                      key={index}
                      type="button"
                      onClick={() => {
                        button.button_type === "secondary"
                          ? handleSecondary && handleSecondary()
                          : handlePrimary && handlePrimary();
                      }}
                      className={`${button.button_type}-button`}
                    >
                      {translate(button.label_en, button.label_ar)}
                    </button>
                  )
              )
          : buttons &&
            !buttons.is_hide && (
              <button
                type="button"
                onClick={handlePrimary && handlePrimary}
                className={`${buttons.button_type}-button single-button`}
              >
                {translate(buttons.label_en, buttons.label_ar)}
              </button>
            )}
      </div>
      <div className="mt-6 text-sm text-gray-600 disclaimer">
        {translate(disclaimer?.content_en, disclaimer?.content_ar)}
      </div>
    </div>
  );
};

export default UniversityCostCalculator;
