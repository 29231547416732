import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../axiosInstance";
import { apiEndPoints } from "../../api/apiEndpoints";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const fetchLearningCenterHomePageStaticData = createAsyncThunk(
  "fetch_LearningCenter_home_data",
  (queryString, { rejectWithValue }) => {
    return Axios.get(
      `${apiEndPoints.getLearningCenterHomePageData}${queryString || ""}`
    ).then((res) => res.data);
  }
);

export const LCHomeSlice = createSlice({
  name: "LCHome",
  initialState,
  reducers: {
    clearLCHomePageError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // FETCH HOME DATA API
    builder.addCase(
      fetchLearningCenterHomePageStaticData.pending,
      (state, action) => {
        if (!state.data) {
          state.isLoading = true;
        }
      }
    );
    builder.addCase(
      fetchLearningCenterHomePageStaticData.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = action.payload?.data?.attributes;
      }
    );
    builder.addCase(
      fetchLearningCenterHomePageStaticData.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.error?.message;
      }
    );
  },
});

// SELECTORS
export const selectLCHomePageWhole = (store) =>
  store.learningCenterHomePageStatic;
export const selectLCHomePageData = (store) =>
  store.learningCenterHomePageStatic?.data;
export const selectLCHomePageLoader = (store) =>
  store.learningCenterHomePageStatic?.isLoading;

// ACTIONS TO DISPATCH
export const { clearLCHomePageError } = LCHomeSlice.actions;

export default LCHomeSlice.reducer;
