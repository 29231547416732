import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const setLanguageInLocalStorage = (lang = "en") => {
    localStorage.setItem("solidarity_lang", lang);
}
export const getLanguageInLocalStorage = () => {
    return localStorage.getItem('solidarity_lang') || "en"
}

const useLanguagePercistant = () => {
    const { i18n } = useTranslation();
    useEffect(() => {
        let current_language = getLanguageInLocalStorage();
        i18n.changeLanguage( current_language )
    }, [])
}

export default useLanguagePercistant;