import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { DevAxios } from "../axiosInstance";
import { apiEndPoints } from "../../api/apiEndpoints";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const fetchLearningCenterCategoryStaticData = createAsyncThunk(
  "fetch_LearningCenter_category_data",
  (queryString, { rejectWithValue }) => {
    return Axios.get(
      `${apiEndPoints.getLearningCenterCategoryStaticData(queryString || "")}`
    ).then((res) => res.data);
  }
);

export const LCCategorySlice = createSlice({
  name: "LCCategory", //Learning Center category
  initialState,
  reducers: {
    clearLCCategoryPageError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // FETCH HOME DATA API
    builder.addCase(
      fetchLearningCenterCategoryStaticData.pending,
      (state, action) => {
        if (!state.data) {
          state.isLoading = true;
        }
      }
    );
    builder.addCase(
      fetchLearningCenterCategoryStaticData.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = action.payload?.data;
      }
    );
    builder.addCase(
      fetchLearningCenterCategoryStaticData.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.error?.message;
      }
    );
  },
});

// SELECTORS
export const selectLCCategoryPageWhole = (store) =>
  store.learningCenterCategoryPageStatic;
export const selectLCCategoryPageData = (store) =>
  store.learningCenterCategoryPageStatic?.data;
export const selectLCCategoryPageLoader = (store) =>
  store.learningCenterCategoryPageStatic?.isLoading;

// ACTIONS TO DISPATCH
export const { clearLCCategoryPageError } = LCCategorySlice.actions;

export default LCCategorySlice.reducer;
