const overview = (slug) => ({
   
    name: { 
      en: "Overview", 
      ar: "نظرة عامة" 
    },
    link: `/product/${slug}`,
    isInternal: true
  });
  
  export default overview;