import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { DevAxios } from "../axiosInstance";
import { apiEndPoints } from "../../api/apiEndpoints";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const fetchSingleArticleStaticData = createAsyncThunk(
  "fetch_single_article_static_data",
  (queryString, { rejectWithValue }) => {
    return Axios.get(
      `${apiEndPoints.getArticlePgeStaticData}${queryString || ""}`
    ).then((res) => res.data);
  }
);

export const ArticleStaticSlice = createSlice({
  name: "ArticleStatic", //Learning Center category
  initialState,
  reducers: {
    clearArticlePgStaticError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSingleArticleStaticData.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchSingleArticleStaticData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data?.attributes;
    });
    builder.addCase(fetchSingleArticleStaticData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });
  },
});

// SELECTORS
export const selectArticlePgStaticWhole = (store) => store.articleStatic;
export const selectArticlePgStaticData = (store) => store.articleStatic?.data;
export const selectArticlePgStaticLoader = (store) =>
  store.articleStatic?.isLoading;

// ACTIONS TO DISPATCH
export const { clearArticlePgStaticError } = ArticleStaticSlice.actions;

export default ArticleStaticSlice.reducer;
