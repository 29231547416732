import React from "react";

const Loader = ({show}) => {
  return (
    show &&
    <div className="fixed inset-0 bg-white bg-opacity-60 flex items-center justify-center z-[199999]">
      <div className="w-12 h-12 rounded-full animate-spin border-4 border-blue-500 border-t-transparent shadow-md"></div>
    </div>
  );
};

export default Loader;
