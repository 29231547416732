import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import { BASE_URL } from "../../api/baseURL";
import ReactGA from "react-ga";
import { speak_to_advisor, call_us } from "../../utils/slugs";

const DropdownMenu = forwardRef(
  ({ isOpen, toggleDropdown, data, className, toggleModal, closeHamburgerMenu}, ref) => {
    const translate = useLanguageTranslate();

    const handleLinkClick = () => {
      toggleDropdown();
      closeHamburgerMenu();
    };

    const handleModal = () => {
      toggleModal();
    };

    const trackEvent = (category, action) => {
      ReactGA.event({
        category: category,
        action: action,
      });
    };

    const NumberText = (text ) => {
      const processedText = text.split(/(\d+)/).map((part, index) =>
        /\d+/.test(part) ? (
          <strong key={index} className="number" style={{fontFamily: 'Roboto !important'}}>
            {part}
          </strong>
        ) : (
          <span key={index}>{part}</span>
        )
      );
    
      return <span className="call-w-full" dir="ltr">{processedText}</span>; // add ltr for number direction
    };
    

    return (
      <>
        {isOpen && (
          <div ref={ref} className={`flex p-4 sd level1 ${className}`}>
            {data?.map((section, sectionIndex) => (
              <div
                key={sectionIndex}
                className="mr-8 childLevel1"
                id={`${className}-section-${sectionIndex}`}
              >
                <div className="subMenuHead">
                  {section?.category?.icon && (
                    <span>
                    <img
                     role="menu"
                      src={`${BASE_URL}${section.category.icon?.img?.data?.attributes.url}`}
                      alt={translate(
                        section?.category?.icon?.img_alt_en,
                        section?.category?.icon?.img_alt_ar
                      )}
                    />
                    </span>
                  )}
                  <h3 className="font-semibold" role="menu">
                    {translate(
                      section?.category?.name?.en,
                      section?.category?.name?.ar
                    )}
                  </h3>
                </div>
                <ul className="claimLevel1">
                  {section?.items?.filter(item => !item.is_hide_in_header).map((item, itemIndex) => (
                    <li key={itemIndex}>
                      {item?.isInternal ? (
                        item?.custom_action !== speak_to_advisor ? (
                          <Link
                            to={item.link}
                            className="flex gap-2 items-center"
                            onClick={() => {
                              handleLinkClick();
                              trackEvent('Internal Link Click', item.name.en); // Track internal link click
                            }}
                          >
                            {item.icon && (
                              <span>
                              <img
                                src={`${BASE_URL}${item.icon.data}`}
                                alt={translate(
                                  item?.icon?.img_alt_en,
                                  item?.icon?.img_alt_ar
                                )}
                              />
                              </span>
                            )}
                            {translate(item?.name.en, item?.name.ar)}
                          </Link>
                        ) : (
                          <Link
                            className="flex gap-2 items-center"
                            onClick={() => {
                              handleModal();
                              trackEvent('Modal Link Click', item?.name.en); // Track modal link click
                            }}
                          >
                            {item?.icon && (
                              <span>
                              <img
                                src={`${BASE_URL}${item?.icon?.data}`}
                                alt={translate(
                                  item?.icon?.img_alt_en,
                                  item?.icon?.img_alt_ar
                                )}
                              />
                              </span>
                            )}
                            {translate(item?.name?.en, item?.name?.ar)}
                          </Link>
                        )
                      ) : (
                        
                        <a
                        href={
                          item?.custom_action === call_us ? `tel:${item?.link}` : item?.link
                        }
                          className="flex gap-2 items-center"
                          onClick={() => {
                            handleLinkClick();
                            trackEvent('External Link Click', item?.name.en); // Track external link click
                          }}
                        >
                          {item?.icon && (
                            <span>
                            <img
                              src={`${BASE_URL}${item.icon.data}`}
                              alt={translate(
                                item?.icon?.img_alt_en,
                                item?.icon?.img_alt_ar
                              )}
                            />
                            </span>
                          )}
                          {item?.custom_action === call_us ?
                               <span className="call-w-full" dir="ltr">{NumberText(translate(item?.name.en, item?.name.ar))}</span>
                               :
                          <span >
                            {translate(item?.name.en, item?.name.ar)}
                          </span>}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
);

export default DropdownMenu;
