import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPageNotFound } from '../../../api/apiActions';
import { BASE_URL } from '../../../api/baseURL';
import ProgressBar from '../../../progressBar';
import useLanguageTranslate from '../../../hooks/useLanguageTranslate';
import ReactGA from 'react-ga'; // Import ReactGA
import ReactHelmet from '../../../components/common/ReactHelmet/ReactHelmet';

const NotFound = () => {
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const [pageNotFoundData, setPageNotFoundData] = useState(null);
  const [BGImage, setBGImage] = useState(null);
  const [loading, setLoading] = useState(true);

  const getInitialData = async () => {
    try {
      const response = await getPageNotFound();
      setPageNotFoundData(response?.data?.attributes);
      const imageURL = `${BASE_URL}${response?.data?.attributes.background.img.data.attributes.url}`;
      setBGImage(imageURL);
    } catch (e) {
      console.log('api data error', e);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (BGImage) {
      const img = new Image();
      img.src = BGImage;
      img.onload = () => {
        setLoading(false);
        ReactGA.event({
          category: '404 Page',
          action: 'Background Image Loaded'
        });
      };
    }
  }, [BGImage]);

  const links = t('notFound.links', { returnObjects: true });

  return loading ? (
    <ProgressBar />
  ) : (
    <>{pageNotFoundData?.seo_meta && <ReactHelmet seo_meta={pageNotFoundData.seo_meta}/>}
    <div className="relative flex justify-center overflow-hidden">
      <img
        src={BGImage}
        alt={
          translate(pageNotFoundData?.background?.img_alt_en,
          pageNotFoundData?.background?.img_alt_ar)
        }
        className="absolute inset-0 w-full h-full object-cover z-0"
      />
      <div className="relative z-10 bg-white bg-opacity-70 p-10 rounded-md shadow-md notFoundBox">
        <p className="text-xl font-bold mb-4">
          {translate(
            pageNotFoundData?.notfound?.subtitle_en,
            pageNotFoundData?.notfound?.subtitle_ar
          )}
        </p>
        <h1 className="text-4xl mb-6 leading-snug sectionHead">
          {translate(
            pageNotFoundData?.notfound?.title_en,
            pageNotFoundData?.notfound?.title_ar
          )}
        </h1>
        <p>
          {translate(
            pageNotFoundData?.notfound?.description_en,
            pageNotFoundData?.notfound?.description_ar
          )}
        </p>
        <ul className="mt-4 space-y-2 linkListing">
          {links.map(
            (link, index) =>
              !["/quick-pay", "/site-map", "/product/online-plans"].includes(
                link.href
              ) && (
                <li key={index}>
                  <Link
                    to={link.href}
                    className="text-black underline"
                    onClick={() => {
                      ReactGA.event({
                        category: "404 Page",
                        action: "Link Clicked",
                        label: link.text,
                      });
                    }}
                  >
                    {link.text}
                  </Link>
                </li>
              )
          )}
        </ul>
      </div>
    </div>
    </>
  );
};

export default NotFound;
