import { online_plans_slug, s_and_p_plan_slug } from "../../utils/slugs";

export const ar = {
  "translation": {
    "header": {
      "signin": "تسجيل الدخول",
      "generalInsurance": "التأمين العام",
      "generalInsuranceMenu": {
        "1": "التأمين العام"
      },
      "lifeInsurance": "تأمين الحياة",
      "lifeInsuranceMenu": {
        "1": "تأمين الحياة"
      },
      "forBusiness": "المطالبات",
      "claims": "الدعم",
      "support": "تسجيل الدخول",
      "getQuote": "تسعيرة التأمين"
    },
    "calculators":{
      "ChildCalculator":{
      "title": "هدف تعليم الطفل",
      "description":
        "أخبرنا قليلاً عن نفسك. إن سنك وأهدافك المالية وتفضيلاتك الاستثمارية تساعدنا في تصميم توصيات تناسب احتياجاتك وتطلعاتك.",
      "name": "ما اسمك؟",
      "email": "ما هو بريدك الإلكتروني؟",
      "mobile": "ما هو رقم هاتفك المحمول؟",
      "university": "أين ترغب في إرسال طفلك إلى الجامعة؟",
      "universityMind": "هل لديك جامعة معينة في الاعتبار؟",
      "plan": "مدة الخطة",
      "inflation": "معدل التضخم",
      "button": "احسب"
    },
    "ChildCalculatorResult":{
      "averageTuitionFees": "متوسط رسوم الدراسة لمدة 4 سنوات",
      "averageLivingCosts": "متوسط تكلفة المعيشة لمدة 4 سنوات",
      "totalCostWithoutInflation": "إجمالي متوسط تكلفة التعليم الجامعي بنسبة 0% تضخم",
      "totalCostWithInflation": "إجمالي متوسط تكلفة التعليم الجامعي مع تضخم {inflationRate}%"
    },
    "CoverageCalculator": {
      "title": "حاسبة التغطية",
      "description":
        "أخبرنا قليلاً عن نفسك. إن سنك وأهدافك المالية وتفضيلاتك الاستثمارية تساعدنا في تصميم توصيات تناسب احتياجاتك وتطلعاتك.",
      "name": "ما اسمك؟",
      "email": "ما هو بريدك الإلكتروني؟",
      "mobile": "ما هو رقم هاتفك المحمول؟",
      "dateOfBirth": "تاريخ الميلاد",
      "CPRNumber": "ما هو رقم السجل المدني الخاص بك؟",
      "currency": "عملة",
      "howMuchAmount": "كم تجني في الشهر",
      "howMuchSave": "كم يمكنك توفيره شهريًا",
      "button": "احسب"
    },  
    "CoverageCalculatorResult":{
      "studyPreparedFor": "دراسة معدة لـ",
      "date": "تاريخ",
      "illustrationText": "التوضيح أدناه يشرح مقدار التأمين على الحياة المطلوب كدخل بديل لعائلتك.",
      "years": "سنوات",
      "coverageAmount": "مبلغ التغطية"
    }
    },
    "validation":{
      "required":"هذه الخانة مطلوبه",
      "invalidEmail":"عنوان البريد الإلكتروني غير صالح",
      "invalidMobile":"رقم الهاتف المحمول غير صالح",
      "invalidName": "يمكن أن يحتوي الاسم على أحرف أبجدية ومسافات فقط",
      "invalidNumber":"يرجى إدخال أرقام فقط.",
      "invalidCPR":"الرجاء إدخال 9 أرقام فقط.",
      "futureDate": "لا يمكن أن يكون تاريخ الميلاد في المستقبل"
    },
    "notFound":{
      "links":[
        {"text":"بيت","href":"/"},
        {"text":"خطط التأمين على الحياة","href":`/product/${s_and_p_plan_slug}`},
        {"text":"شراء عبر الإنترنت","href":`/product/${online_plans_slug}`},
        {"text":"حاسبة الطفل","href":"/child-education-goal-calculator"},
        {"text":"حاسبة التغطية","href":"/insurance-coverage-calculator"},
        {"text":"الدفع السريع","href":"/quick-pay"},
        {"text":"خريطة الموقع","href":"/site-map"}
      ]
    },
  }
}