import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import SocialLinks from "../common/socialLinks/socialLinks";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import logo from "../../assets/images/Logo.svg";
import Applelogo from "../../assets/images/Apple.svg";
import googlelogo from "../../assets/images/Google.svg";
import { useSelector } from "react-redux";
import { selectHeaderMenu } from "../../redux/features/toolsSlice";

import SpeakAdvisorModal from "../../pages/preLogin/modal/speakAdvisorModal";
import overview from "../common/overviewItem";
import FooterData from "./footerData";
import FooterdataArabic from "./footerarabic";
import { call_us, speak_to_advisor } from "../../utils/slugs";
import ReactGA from "react-ga";
import { selectPlanHeader } from "../../redux/features/plansSlice";

const Footer = () => {
  const translate = useLanguageTranslate();

  const [render, setRender] = useState(null);
  const [menuGroups, setMenuGroups] = useState([]);
  const [isSpeakToAdvisor, setIsSpeakToAdvisor] = useState(false);

  const toggleModal = () => {
    setIsSpeakToAdvisor(!isSpeakToAdvisor);
  };

  const headerdata = useSelector(selectPlanHeader);
  const headermenu = useSelector(selectHeaderMenu);
  useEffect(() => {
    if (headerdata && headermenu) {
      const formattedData = headerdata.map((category) => {
        const { attributes } = category;
        const slug = attributes.slug;

        const submenu = attributes.plans?.data?.map((plan) => ({
          name: {
            en: plan.attributes.content.subtitle_en,
            ar: plan.attributes.content.subtitle_ar,
          },

          link: `/product/${slug}/${plan.attributes.slug}`,
          isInternal: true,
        }));
        const overviewItem = overview(slug);

        return {
          category: {
            name: {
              en: attributes.content.subtitle_en,
              ar: attributes.content.subtitle_ar,
            },
          },
          items: [overviewItem, ...submenu],
        };
      });

      setRender(formattedData);
      setMenuGroups(headermenu);
    }
  }, [headerdata, headermenu]);

  
    const trackGAEvent = (category, action, label) => {
      ReactGA.event({
        category: category,
        label: label,
        action: action
      });
    };

    if (!headermenu || headermenu.length === 0) {
      return null; // Do not render the header if headermenu is null or empty
    }
  return (
    <footer className="flex footer-section">
      <div className="container mx-auto footer-flex">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
          <div className="w-/5 md:w-5/5 lg:w-5/5 xl:w-5/5">
            <div className="logo-footer-box">
              <img src={logo} alt="logo" />
            </div>
            <div className="apple-logo-box flex">
              <a
                href="https://apps.apple.com/bh/app/solidarity/id1585138622"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  trackGAEvent("Download App", "Click", "Apple Store")
                }
              >
                <img
                  className="applelogoimg"
                  src={Applelogo}
                  alt="Apple logo"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=bh.solidarity.app&hl=en&gl=US"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  trackGAEvent("Download App", "Click", "Google Play")
                }
              >
                <img
                  className="applelogoimg"
                  src={googlelogo}
                  alt="Google logo"
                />
              </a>
            </div>
            <SocialLinks />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
          {/* Render menu groups with id 3 and 4 (class 'def') */}
          <div className="columns">
            {menuGroups?.map((groupData) =>
              groupData?.attributes?.menu_groups?.data.map((menuGroup) => {
                if (menuGroup.id === 3) {
                  return (
                    <div
                      key={menuGroup.id}
                      className="footer-list-box columns Insurance-Policies"
                    >
                      <h4>
                        {translate(
                          menuGroup?.attributes?.menu_group?.category_en,
                          menuGroup?.attributes?.menu_group?.category_ar
                        )}
                      </h4>
                      <ul>
                        {menuGroup?.attributes?.menu_group?.menu?.map(
                          (menuItem) =>
                            menuItem?.is_hide_in_footer === false ||
                            menuItem?.is_hide_in_footer === null ? (
                              <li key={menuItem?.id}>
                                {menuItem?.is_internal ? (
                                  <Link
                                    to={menuItem?.link}
                                    onClick={() =>
                                      trackGAEvent(
                                        "Internal Links",
                                        "Click",
                                        menuItem.label_en
                                      )
                                    }
                                  >
                                    {translate(
                                      menuItem?.label_en,
                                      menuItem?.label_ar
                                    )}
                                  </Link>
                                ) : (
                                  <a
                                    href={menuItem?.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() =>
                                      trackGAEvent(
                                        "Internal Links",
                                        "Click",
                                        menuItem.label_en
                                      )
                                    }
                                  >
                                    {translate(
                                      menuItem?.label_en,
                                      menuItem?.label_ar
                                    )}
                                  </a>
                                )}
                              </li>
                            ) : null
                        )}
                      </ul>
                    </div>
                  );
                }
                return null;
              })
            )}
            {menuGroups?.map((groupData) =>
              groupData?.attributes?.menu_groups?.data.map((menuGroup) => {
                if (menuGroup.id === 4) {
                  return (
                    <div
                      key={menuGroup.id}
                      className="footer-list-box w-full columns Claims hidden md:block"
                    >
                      <h4>
                        {translate(
                          menuGroup?.attributes?.menu_group?.category_en,
                          menuGroup?.attributes?.menu_group?.category_ar
                        )}
                      </h4>
                      <ul>
                        {menuGroup?.attributes?.menu_group?.menu?.map(
                          (menuItem) =>
                            menuItem?.is_hide_in_footer === false ||
                            menuItem?.is_hide_in_footer === null ? (
                              <li key={menuItem?.id}>
                                {menuItem?.is_internal ? (
                                  <Link
                                    to={menuItem?.link}
                                    onClick={() =>
                                      trackGAEvent(
                                        "Internal Links",
                                        "Click",
                                        menuItem.label_en
                                      )
                                    }
                                  >
                                    {translate(
                                      menuItem?.label_en,
                                      menuItem?.label_ar
                                    )}
                                  </Link>
                                ) : (
                                  <a
                                    href={menuItem?.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() =>
                                      trackGAEvent(
                                        "Internal Links",
                                        "Click",
                                        menuItem.label_en
                                      )
                                    }
                                  >
                                    {translate(
                                      menuItem?.label_en,
                                      menuItem?.label_ar
                                    )}
                                  </a>
                                )}
                              </li>
                            ) : null
                        )}
                      </ul>
                    </div>
                  );
                }
                return null;
              })
            )}
          </div>
          <div className="columns">
            {render?.slice(0, 1).map((categoryData, index) => (
              <div
                key={index}
                className={`footer-list-box columns Savings-Protection-Plans`}
              >
                <h4>
                  {translate(
                    categoryData.category.name.en,
                    categoryData.category.name.ar
                  )}
                </h4>
                <ul>
                  {categoryData.items?.map((item, idx) => (
                    <li key={idx}>
                      {item?.isInternal ? (
                        <Link
                          to={item.link}
                          onClick={() =>
                            trackGAEvent(
                              "Internal Links",
                              "Click",
                              item.name.en
                            )
                          }
                        >
                          {translate(item.name.en, item.name.ar)}
                        </Link>
                      ) : (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() =>
                            trackGAEvent(
                              "External Links",
                              "Click",
                              item.name.en
                            )
                          }
                        >
                          {translate(item.name.en, item.name.ar)}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            {render?.slice(1, 2).map((categoryData, index) => (
              <div
                key={index}
                className={`footer-list-box w-full columns Protection-Plans hidden md:block`}
              >
                <h4>
                  {translate(
                    categoryData.category.name.en,
                    categoryData.category.name.ar
                  )}
                </h4>
                <ul>
                  {categoryData.items?.map((item, idx) => (
                    <li key={idx}>
                      {item?.isInternal ? (
                        <Link
                          to={item.link}
                          onClick={() =>
                            trackGAEvent(
                              "Internal Links",
                              "Click",
                              item.name.en
                            )
                          }
                        >
                          {translate(item.name.en, item.name.ar)}
                        </Link>
                      ) : (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() =>
                            trackGAEvent(
                              "External Links",
                              "Click",
                              item.name.en
                            )
                          }
                        >
                          {translate(item.name.en, item.name.ar)}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="column block md:hidden">
            {render?.slice(1, 2).map((categoryData, index) => (
              <div
                key={index}
                className={`footer-list-box w-full columns Protection-Plans `}
              >
                <h4>
                  {translate(
                    categoryData.category.name.en,
                    categoryData.category.name.ar
                  )}
                </h4>
                <ul>
                  {categoryData.items?.map((item, idx) => (
                    <li key={idx}>
                      {item?.isInternal ? (
                        <Link
                          to={item.link}
                          onClick={() =>
                            trackGAEvent(
                              "Internal Links",
                              "Click",
                              item.name.en
                            )
                          }
                        >
                          {translate(item.name.en, item.name.ar)}
                        </Link>
                      ) : (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() =>
                            trackGAEvent(
                              "External Links",
                              "Click",
                              item.name.en
                            )
                          }
                        >
                          {translate(item.name.en, item.name.ar)}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="columns">
            {/* Separate div for index 2 */}
            {render && render.length > 2 && (
              <div
                key={2}
                className={`footer-list-box columns Buy-Online-Plans`}
              >
                <h4>
                  {translate(
                    render[2].category.name.en,
                    render[2].category.name.ar
                  )}
                </h4>
                <ul>
                  {render[2].items?.map((item, idx) => (
                    <li key={idx}>
                      {item?.isInternal ? (
                        <Link
                          to={item.link}
                          onClick={() =>
                            trackGAEvent(
                              "Internal Links",
                              "Click",
                              item.name.en
                            )
                          }
                        >
                          {translate(item.name.en, item.name.ar)}
                        </Link>
                      ) : (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() =>
                            trackGAEvent(
                              "External Links",
                              "Click",
                              item.name.en
                            )
                          }
                        >
                          {translate(item.name.en, item.name.ar)}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {menuGroups?.map((groupData) =>
              groupData?.attributes?.menu_groups?.data.map((menuGroup) => {
                if (menuGroup.id === 6) {
                  return (
                    <div
                      key={menuGroup.id}
                      className="footer-list-box columns Resources hidden md:block"
                    >
                      <h4>
                        {translate(
                          menuGroup?.attributes?.menu_group?.category_en,
                          menuGroup?.attributes?.menu_group?.category_ar
                        )}
                      </h4>
                      <ul>
                        {menuGroup?.attributes?.menu_group?.menu?.map(
                          (menuItem) => (
                            <li key={menuItem?.id}>
                              {menuItem?.is_internal ? (
                                <Link
                                  to={menuItem?.link}
                                  onClick={() =>
                                    trackGAEvent(
                                      "Internal Links",
                                      "Click",
                                      menuItem.label_en
                                    )
                                  }
                                >
                                  {translate(
                                    menuItem?.label_en,
                                    menuItem?.label_ar
                                  )}
                                </Link>
                              ) : (
                                <a
                                  href={menuItem?.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={() =>
                                    trackGAEvent(
                                      "External Links",
                                      "Click",
                                      menuItem.label_en
                                    )
                                  }
                                >
                                  {translate(
                                    menuItem?.label_en,
                                    menuItem?.label_ar
                                  )}
                                </a>
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                    
                  );
                }
                return null;
              })
            )}
              {menuGroups?.map((groupData) =>
              groupData?.attributes?.menu_groups?.data.map((menuGroup) => {
                if (menuGroup.id === 7) {
                  return (
                    <div
                      key={menuGroup.id}
                      className="footer-list-box More hidden md:block"
                    >
                      <h4>
                        {translate(
                          menuGroup?.attributes?.menu_group?.category_en,
                          menuGroup?.attributes?.menu_group?.category_ar
                        )}
                      </h4>
                      <ul>
                        {menuGroup?.attributes?.menu_group?.menu?.map(
                          (menuItem) =>
                            menuItem?.is_hide_in_footer === false ||
                            menuItem?.is_hide_in_footer === null ? (
                              <li key={menuItem?.id}>
                                {menuItem?.is_internal ? (
                                  <Link
                                    to={menuItem?.link}
                                    onClick={() =>
                                      trackGAEvent(
                                        "Internal Links",
                                        "Click",
                                        menuItem.label_en
                                      )
                                    }
                                  >
                                    {translate(
                                      menuItem?.label_en,
                                      menuItem?.label_ar
                                    )}
                                  </Link>
                                ) : (
                                  <a
                                    href={menuItem?.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() =>
                                      trackGAEvent(
                                        "External Links",
                                        "Click",
                                        menuItem.label_en
                                      )
                                    }
                                  >
                                    {translate(
                                      menuItem?.label_en,
                                      menuItem?.label_ar
                                    )}
                                  </a>
                                )}
                              </li>
                            ) : null
                        )}
                      </ul>
                    </div>
                  );
                }
                return null;
              })
            )}
          </div>
          <div className="columns block md:hidden">
            {menuGroups?.map((groupData) =>
              groupData?.attributes?.menu_groups?.data.map((menuGroup) => {
                if (menuGroup.id === 4) {
                  return (
                    <div
                      key={menuGroup.id}
                      className="footer-list-box w-full columns Claims"
                    >
                      <h4>
                        {translate(
                          menuGroup?.attributes?.menu_group?.category_en,
                          menuGroup?.attributes?.menu_group?.category_ar
                        )}
                      </h4>
                      <ul>
                        {menuGroup?.attributes?.menu_group?.menu?.map(
                          (menuItem) => (
                            <li key={menuItem?.id}>
                              {menuItem?.is_internal ? (
                                <Link
                                  to={menuItem?.link}
                                  onClick={() =>
                                    trackGAEvent(
                                      "Internal Links",
                                      "Click",
                                      menuItem.label_en
                                    )
                                  }
                                >
                                  {translate(
                                    menuItem?.label_en,
                                    menuItem?.label_ar
                                  )}
                                </Link>
                              ) : (
                                <a
                                  href={menuItem?.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={() =>
                                    trackGAEvent(
                                      "Internal Links",
                                      "Click",
                                      menuItem.label_en
                                    )
                                  }
                                >
                                  {translate(
                                    menuItem?.label_en,
                                    menuItem?.label_ar
                                  )}
                                </a>
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  );
                }
                return null;
              })
            )}
          </div>
          <div className="columns block md:hidden">
            {menuGroups?.map((groupData) =>
              groupData?.attributes?.menu_groups?.data.map((menuGroup) => {
                if (menuGroup.id === 6) {
                  return (
                    <div
                      key={menuGroup.id}
                      className="footer-list-box columns Resources "
                    >
                      <h4>
                        {translate(
                          menuGroup?.attributes?.menu_group?.category_en,
                          menuGroup?.attributes?.menu_group?.category_ar
                        )}
                      </h4>
                      <ul>
                        {menuGroup?.attributes?.menu_group?.menu?.map(
                          (menuItem) =>
                            menuItem?.is_hide_in_footer === false ||
                            menuItem?.is_hide_in_footer === null ? (
                              <li key={menuItem?.id}>
                                {menuItem?.is_internal ? (
                                  <Link
                                    to={menuItem?.link}
                                    onClick={() =>
                                      trackGAEvent(
                                        "Internal Links",
                                        "Click",
                                        menuItem.label_en
                                      )
                                    }
                                  >
                                    {translate(
                                      menuItem?.label_en,
                                      menuItem?.label_ar
                                    )}
                                  </Link>
                                ) : (
                                  <a
                                    href={menuItem?.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() =>
                                      trackGAEvent(
                                        "External Links",
                                        "Click",
                                        menuItem.label_en
                                      )
                                    }
                                  >
                                    {translate(
                                      menuItem?.label_en,
                                      menuItem?.label_ar
                                    )}
                                  </a>
                                )}
                              </li>
                            ) : null
                        )}
                      </ul>
                    </div>
                    
                  );
                }
                return null;
              })
            )}
            
          </div>
          
          <div className="columns">
            {/* Render menu groups with id 5 and 7 (class 'jjj') */}
            {menuGroups?.map((groupData) =>
              groupData?.attributes?.menu_groups?.data.map((menuGroup) => {
                if (menuGroup.id === 5) {
                  return (
                    <div
                      key={menuGroup.id}
                      className="footer-list-box columns Support"
                    >
                      <h4>
                        {translate(
                          menuGroup?.attributes?.menu_group?.category_en,
                          menuGroup?.attributes?.menu_group?.category_ar
                        )}
                      </h4>
                      <ul>
                        {menuGroup?.attributes?.menu_group?.menu?.map(
                          (menuItem) =>
                            // Only render <li> if menuItem is not hidden in footer
                            menuItem?.is_hide_in_footer === false ||
                            menuItem?.is_hide_in_footer === null ? (
                              <li key={menuItem?.id}>
                                {menuItem?.is_internal ? (
                                  menuItem?.custom_action !==
                                  speak_to_advisor ? (
                                    <Link
                                      to={menuItem?.link}
                                      onClick={() => {
                                        trackGAEvent(
                                          "Internal Links",
                                          "Click",
                                          menuItem.label_en
                                        );
                                      }}
                                    >
                                      {translate(
                                        menuItem?.label_en,
                                        menuItem?.label_ar
                                      )}
                                    </Link>
                                  ) : (
                                    <Link
                                      className="flex gap-2 items-center"
                                      onClick={() => {
                                        trackGAEvent(
                                          "Internal Links",
                                          "Click",
                                          menuItem.label_en
                                        );
                                        toggleModal();
                                      }}
                                    >
                                      {translate(
                                        menuItem?.label_en,
                                        menuItem?.label_ar
                                      )}
                                    </Link>
                                  )
                                ) : (
                                  <a
                                    href={
                                      menuItem?.custom_action === call_us
                                        ? `tel:${menuItem?.link}`
                                        : menuItem?.link
                                    }
                                    onClick={() =>
                                      trackGAEvent(
                                        "External Links",
                                        "Click",
                                        menuItem.label_en
                                      )
                                    }
                                  >
                                    {translate(
                                      menuItem?.label_en,
                                      menuItem?.label_ar
                                    )}
                                  </a>
                                )}
                              </li>
                            ) : null
                        )}
                      </ul>
                    </div>
                  );
                }
                return null;
              })
            )}

          
          </div>
          <div className="columns block md:hidden">
            {menuGroups?.map((groupData) =>
              groupData?.attributes?.menu_groups?.data.map((menuGroup) => {
                if (menuGroup.id === 7) {
                  return (
                    <div key={menuGroup.id} className="footer-list-box More">
                      <h4>
                        {translate(
                          menuGroup?.attributes?.menu_group?.category_en,
                          menuGroup?.attributes?.menu_group?.category_ar
                        )}
                      </h4>
                      <ul>
                        {menuGroup?.attributes?.menu_group?.menu?.map(
                          (menuItem) => (
                            <li key={menuItem?.id}>
                              {menuItem?.is_internal ? (
                                <Link
                                  to={menuItem?.link}
                                  onClick={() =>
                                    trackGAEvent(
                                      "Internal Links",
                                      "Click",
                                      menuItem.label_en
                                    )
                                  }
                                >
                                  {translate(
                                    menuItem?.label_en,
                                    menuItem?.label_ar
                                  )}
                                </Link>
                              ) : (
                                <a
                                  href={menuItem?.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={() =>
                                    trackGAEvent(
                                      "External Links",
                                      "Click",
                                      menuItem.label_en
                                    )
                                  }
                                >
                                  {translate(
                                    menuItem?.label_en,
                                    menuItem?.label_ar
                                  )}
                                </a>
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  );
                }
                return null;
              })
            )}
          </div>

          {/* Render menu group with id 6 (class 'abc') */}
        </div>
      </div>

      {translate(<FooterData />, <FooterdataArabic />)}

      <SpeakAdvisorModal
        showModal={isSpeakToAdvisor}
        toggleModal={toggleModal}
      />
    </footer>
  );
};

export default Footer;
