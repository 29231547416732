import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./features/homeSlice";
import plansReducer from "./features/plansSlice";
import toolsReducer from "./features/toolsSlice";
import learningCenterHomePageStaticReducer from "./features/learningCenterHomePgSlice";
import latestArticlesReducer from "./features/latestArticlesSlice";
import articleStaticReducer from "./features/singleArticleStaticSlice";

import learningCenterCategoryPageStaticReducer from "./features/learningCenterCategoryStaticSlice";
import singleArticleReducer from "./features/singleArticleDataSlice";

import calculatorReducer, {
  CalculatorStoreSelector,
} from "./features/calculatorSlice";
import makeAClaimReducer, {
  MakeAClaimStoreSelector,
} from "./features/makeAclaimSlice";

export const store = configureStore({
  reducer: {
    home: homeReducer,
    plans: plansReducer,
    tools: toolsReducer,
    learningCenterHomePageStatic: learningCenterHomePageStaticReducer,
    latestArticles: latestArticlesReducer,
    learningCenterCategoryPageStatic: learningCenterCategoryPageStaticReducer,
    singleArticle: singleArticleReducer,
    articleStatic: articleStaticReducer,
    [MakeAClaimStoreSelector]: makeAClaimReducer,
    [CalculatorStoreSelector]: calculatorReducer,
  },
});
