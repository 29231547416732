export const universityData =[
    {"name": "Bahrain", "value": "Bahrain"},
    {"name": "UAE", "value": "UAE"},
    {"name": "Saudi Arabia", "value": "Saudi Arabia"},
    {"name": "Kuwait", "value": "Kuwait"},
    {"name": "Qatar", "value": "Qatar"},
    {"name": "Oman", "value": "Oman"},
    {"name": "USA", "value": "USA"},
    {"name": "UK", "value": "UK"},
    {"name": "Australia", "value": "Australia"},
    {"name": "China", "value": "China"},
    {"name": "India", "value": "India"},
    {"name": "Canada", "value": "Canada"},
    {"name": "Lebanon", "value": "Lebanon"},
    {"name": "Turkey", "value": "Turkey"},
    {"name": "Malaysia", "value": "Malaysia"},
    {"name": "Jordan", "value": "Jordan"},
    {"name": "Egypt", "value": "Egypt"},
    {"name": "Germany", "value": "Germany"}
]

export function getCurrentAge(day, month, year) {
    // Convert the input to a date object
    const birthDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    // Calculate the difference in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    // Adjust the age if the current date hasn't reached the birthdate of the current year
    const monthDifference = currentDate.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  export function getCurrentDateFormatted() {
    const date = new Date();
    const day = date.getDate();
    const monthNames = ["January", "February", "March", "April", "May", "June", 
                        "July", "August", "September", "October", "November", "December"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }
