function FooterData() {
  return (
    <>
      <div className="flex footer-bottom-section">
        <h5 className="mb-2">
          <a href="https://www.solidarity.com.bh/termsofuse">Terms of use</a> |
          <a href="https://www.solidarity.com.bh/privacyandsecurity">
            {" "}
            Privacy and Security
          </a>{" "}
          |
          <a
            href="https://content.solidarity.bh/uploads/solidarity_fees_and_charges_493d5a6b2e.pdf"
            target="_blank"
          >
            {" "}
            Fees & Charges
          </a>
        </h5>
        <h6>
        Copyright ©2024 Solidarity Bahrain B.S.C. All rights reserved. Solidarity Bahrain is a Subsidiary of Solidarity Group Holding. Licensed and regulated by the Central Bank of Bahrain as a locally incorporated Takaful Firm – Category four
        </h6>
      </div>
    </>
  );
}
export default FooterData;
