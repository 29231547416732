import React from "react";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import closeicon from "../../../assets/images/Menuicons/clear.svg"; // Importing the image file
const Modal = ({ show, onClose, title, children }) => {
  const translate = useLanguageTranslate();

  if (!show) return null;

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-[999] modal"
    >
      <div className="speck-to-advisor bg-white rounded-lg shadow-lg w-11/12 sm:w-2/3 md:w-1/2 lg:w-1/3 xl:w-1/3">
        <div className="flex justify-between items-center">
          <h1 className="font-semibold">{title}</h1>
          {onClose && (
            <button
              onClick={onClose}
              className="text-gray-600 hover:text-gray-800 btn-close"
            >
              <img src={closeicon} alt="Logo" />
            </button>
          )}
        </div>
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
