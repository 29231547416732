import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCoverageCalculatorData, setCRMLead } from "../../../api/apiActions";
import { MuiPhone } from "../modal/TelInputFinal";
import CoverageCalculatorResult from "./coverageCalculatorResult";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { getCurrentAge } from "../../../utils/constant";
import SpeakAdvisorModal from "../modal/speakAdvisorModal";
import Loader from "../../../components/loader/loader";
import { showToast } from "../../../components/toastify/toastify";
import ProgressBar from "../../../progressBar";
import { BASE_URL } from "../../../api/baseURL";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCoverageCalculator,
  selectCoverageCalculator,
} from "../../../redux/features/calculatorSlice";
import ReactHelmet from "../../../components/common/ReactHelmet/ReactHelmet";
import InputComponent from "../../../components/inputComponent";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3";
import {GOOGLE_RECAPTCHA} from "../../../api/baseURL";
import { selectUtilsInfo } from "../../../redux/features/toolsSlice";

const CoverageCalculator = () => {
  const { i18n, t } = useTranslation();
  const translate = useLanguageTranslate();
  const [days, setDays] = useState([]);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [isResult, setIsResult] = useState(false);
  const [isSpeakToAdvisor, setIsSpeakToAdvisor] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    day: "",
    month: "",
    year: "",
    cprNumber: "",
    currency: "BHD",
    amount: "",
    numberOfYears: "",
  });
  const [errors, setErrors] = useState({});
  const [coverageResultData, setCoverageResultData] = useState([]);
  const [reCaptchaVerified, setReCaptchaVerified] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "mobile") {
      newValue = value.replace(/\D/g, "");
      if (newValue.length > 8) {
        newValue = newValue.substring(0, 8);
      }
    }

    setFormData((prev) => ({ ...prev, [name]: newValue }));
    validateField(name, newValue);
  };

  const handleToggleResult = () => {
    setErrors({});
    setFormData({
      name: "",
      email: "",
      mobile: "",
      day: "",
      month: "",
      year: "",
      cprNumber: "",
      currency: "BHD",
      amount: "",
      numberOfYears: "",
    });
  };

  const validateField = (name, value) => {
    const newErrors = { ...errors };
    switch (name) {
      case "name":
        if (!value) {
          newErrors.name = t("validation.required");
        } else if (!/^[A-Za-z\s]+$/.test(value)) {
          newErrors.name = t("validation.invalidName");
        } else {
          delete newErrors[name];
        }
        break;
      case "email":
        if (!value) {
          newErrors.email = t("validation.required");
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          newErrors.email = t("validation.invalidEmail");
        } else {
          delete newErrors[name];
        }
        break;
      case "mobile":
        if (!value) {
          newErrors.mobile = t("validation.required");
        } else if (!/^\d{8}$/.test(value)) {
          newErrors.mobile = t("validation.invalidMobile");
        } else {
          delete newErrors[name];
        }
        break;
      // case "cprNumber":
      //   if (!value) {
      //     newErrors.cprNumber = t("validation.required");
      //   } else if (!/^\d+$/.test(value)) {
      //     newErrors.cprNumber = t("validation.invalidNumber");
      //   } else {
      //     delete newErrors[name];
      //   }
      //   break;
      case "amount":
        if (!value) {
          newErrors.amount = t("validation.required");
        } else if (!/^\d+$/.test(value)) {
          newErrors.amount = t("validation.invalidNumber");
        } else {
          delete newErrors[name];
        }
        break;
      case "numberOfYears":
        if (!value) {
          newErrors.numberOfYears = t("validation.required");
        } else if (!/^\d+$/.test(value)) {
          newErrors.numberOfYears = t("validation.invalidNumber");
        } else {
          delete newErrors[name];
        }
        break;
      case "day":
      case "month":
      case "year":
        delete newErrors["dateOfBirth"];
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = t("validation.required");
    } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      newErrors.name = t("validation.invalidName");
    }
    if (!formData.email) {
      newErrors.email = t("validation.required");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t("validation.invalidEmail");
    }
    if (!formData.mobile) {
      newErrors.mobile = t("validation.required");
    } else if (!/^\d{8}$/.test(formData.mobile)) {
      newErrors.mobile = t("validation.invalidMobile");
    }
    // if (!formData.cprNumber) {
    //   newErrors.cprNumber = t("validation.required");
    // } else if (!/^\d+$/.test(formData.cprNumber)) {
    //   newErrors.cprNumber = t("validation.invalidNumber");
    // }
    if (!formData.amount) {
      newErrors.amount = t("validation.required");
    } else if (!/^\d+$/.test(formData.amount)) {
      newErrors.amount = t("validation.invalidNumber");
    }
    if (!formData.numberOfYears) {
      newErrors.numberOfYears = t("validation.required");
    } else if (!/^\d+$/.test(formData.numberOfYears)) {
      newErrors.numberOfYears = t("validation.invalidNumber");
    }
    if (!formData.day || !formData.month || !formData.year) {
      newErrors.dateOfBirth = t("validation.required");
    } else {
      const currentDate = new Date();
      const dateOfBirth = new Date(
        formData.year,
        formData.month - 1,
        formData.day,
      );
      if (dateOfBirth > currentDate) {
        newErrors.dateOfBirth = t("validation.futureDate");
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const {crm_common_product_name,crm_common_product_type} = useSelector(selectUtilsInfo)||{};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm() && reCaptchaVerified) {
      setIsSubmitDisabled(true);
      const age = getCurrentAge(formData.day, formData.month, formData.year);
      const payload = {
        amount: formData.amount,
        currency: formData.currency,
        age: age,
        numberOfYears: formData.numberOfYears,
      };
      const crmPayload = {
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        CPR: formData.cprNumber,
        subject : "Coverage Calculator",
        LOBProduct: crm_common_product_name,
        productType: crm_common_product_type || "LIFE"
      };

      try {
        const res = await getCoverageCalculatorData(payload);
        setIsSubmitDisabled(false);
        setCoverageResultData(res);
        setIsResult(!isResult);
      } catch (err) {
        setIsSubmitDisabled(false);
        showToast("error", "internal server error");
      }

      try {
        const crmResponse = await setCRMLead(crmPayload);
        setIsSubmitDisabled(false);
      } catch (err) {
        setIsSubmitDisabled(false);
        // showToast("error", "internal server error");
        return;
      }

      setIsSubmitDisabled(false);
    }
  };

  const handleSecondary = () => {
    setIsResult(!isResult);
    setFormData(prev => ({
      ...prev,
    }));
    // handleToggleResult();
  };

  const toggleModal = () => {
    setIsSpeakToAdvisor(!isSpeakToAdvisor);
  };

  const generateDays = (year, month) => {
    const daysInMonth = new Date(year, month, 0).getDate();
    if (year === undefined || month === undefined) {
      const daysArray = Array.from({ length: 31 }, (_, i) => i + 1);
      setDays(daysArray);
    } else {
      const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
      setDays(daysArray);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isResult]);
  

  useEffect(() => {
    const daysArray = Array.from({ length: 31 }, (_, i) => i + 1);
    setDays(daysArray);

    const monthsArray = Array.from({ length: 12 }, (_, i) => i + 1);
    setMonths(monthsArray);

    const currentYear = new Date().getFullYear();
    const yearsArray = Array.from(
      { length: currentYear - 1900 + 1 },
      (_, i) => 1900 + i,
    );
    setYears(yearsArray);
  }, []);

  useEffect(() => {
    const monthsArray = Array.from({ length: 12 }, (_, i) => i + 1);
    setMonths(monthsArray);

    const currentYear = new Date().getFullYear();
    const yearsArray = Array.from(
      { length: currentYear - 1900 + 1 },
      (_, i) => 1900 + i,
    );
    setYears(yearsArray);

    generateDays(selectedYear, selectedMonth);
  }, []);

  useEffect(() => {
    generateDays(selectedYear, selectedMonth);
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    handleToggleResult();
  }, [i18n.resolvedLanguage]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCoverageCalculator());
  }, []);
  const coverageCalulatorData = useSelector(selectCoverageCalculator);
  if (!coverageCalulatorData) {
    return <ProgressBar />;
  }

  const {
    name_input_label,
    headings,
    mobile_input_label,
    email_input_label,
    result_action_btns,
    result_disclaimer_text,
    banner,
    d_o_b_input_label,
    cpr_label,
    annual_income_label,
    currency_label,
    currency_menu,
    action_btn,
    no_of_years_label,
    seo_meta,
  } = coverageCalulatorData?.attributes;

  const imageURL = `${BASE_URL}${banner.img.data.attributes.url}`;

  return (
    <>
      {seo_meta && <ReactHelmet meta_data={seo_meta} />}
      <div className={`lg:p-10 calculatorBox coverage`}>
        <div
          className={`grid grid-cols-1 md:grid-cols-1 gap-6 p-6 xs:mt-4 bg-white customWrapper container`}>
          <div className={`order-2 md:order-2 md:col-span-1 leftPanel`}>
            <h1
              className="text-2xl font-bold mb-4 articleHead"
              data-testid="heading">
              {translate(headings?.title_en, headings?.title_ar)}
            </h1>
            <p className="mb-6" data-testid="description">
              {translate(headings?.description_en, headings?.description_ar)}
            </p>
            {!isResult ? (
                <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA}>
              <form className="space-y-4" onSubmit={handleSubmit}>
                <InputComponent
                  label={translate(
                    name_input_label?.title_en,
                    name_input_label?.title_ar,
                  )}
                  name={"name"}
                  value={formData.name}
                  handleChange={handleChange}
                  error={errors.name}
                  type="text"
                  data-testid="name"
                />
                <InputComponent
                  label={translate(
                    email_input_label?.title_en,
                    email_input_label?.title_ar,
                  )}
                  name={"email"}
                  value={formData.email}
                  handleChange={handleChange}
                  error={errors.email}
                  type="text"
                  data-testid="email"
                />
                <div className="mobileField">
                  <label className="block text-sm font-medium mb-1">
                    {translate(
                      mobile_input_label?.title_en,
                      mobile_input_label?.title_ar,
                    )}
                  </label>
                  <MuiPhone
                    name="mobile"
                    onChange={(e) => handleChange(e)}
                    value={formData.mobile}
                    data-testid="mobile"
                  />
                  {errors.mobile && (
                    <p className="text-red-500 text-sm">{errors.mobile}</p>
                  )}
                </div>
                <div className="dateField">
                  <label className="block text-sm font-medium mb-1">
                    {translate(
                      d_o_b_input_label?.title_en,
                      d_o_b_input_label?.title_ar,
                    )}
                  </label>
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <div className="w-full md:w-1/3">
                      <select
                        className="w-full border border-gray-300 p-2.5 rounded-lg bg-white"
                        name="day"
                        value={formData.day}
                        onChange={handleChange}
                        data-testid="day">
                        <option value="">{translate("Day", "يوم")}</option>
                        {days.map((day) => (
                          <option key={day} value={day}>
                            {day}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full md:w-1/3 mt-4 md:mt-0">
                      <select
                        className="w-full border border-gray-300 p-2.5 rounded-lg bg-white"
                        name="month"
                        value={formData.month}
                        onChange={(e) => {
                          setSelectedMonth(Number(e.target.value));
                          handleChange(e);
                        }}
                        data-testid="month">
                        <option value="">{translate("Month", "شهر")}</option>
                        {months.map((month) => (
                          <option key={month} value={month}>
                            {month}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full md:w-1/3 mt-4 md:mt-0">
                      <select
                        className="w-full border border-gray-300 p-2.5 rounded-lg bg-white"
                        name="year"
                        value={formData.year}
                        onChange={(e) => {
                          setSelectedYear(Number(e.target.value));
                          handleChange(e);
                        }}
                        data-testid="year">
                        <option value="">{translate("Year", "سنة")}</option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {errors.dateOfBirth && (
                    <p className="text-red-500 text-sm">{errors.dateOfBirth}</p>
                  )}
                </div>
                {/* <div className="numberField">
                  <InputComponent
                    label={translate(cpr_label?.title_en, cpr_label?.title_ar)}
                    name="cprNumber"
                    value={formData.cprNumber}
                    handleChange={handleChange}
                    error={errors.cprNumber}
                    type="text"
                    data-testid="cprNumber"
                  />
                </div> */}
                <div className="currencyField">
                  <div className="flex flex-col md:flex-row md:space-x-2 gap-4">
                    <div className="w-full md:w-1/4">
                      <div className="block text-sm font-medium mb-1 currency-lable">
                        {translate(
                          currency_label?.title_en,
                          currency_label?.title_ar,
                        )}
                      </div>
                      <select
                        className="w-full border border-gray-300 p-2.5 rounded-lg bg-white"
                        name="currency"
                        value={formData.currency}
                        onChange={handleChange}
                        data-testid="currency">
                        {currency_menu &&
                          currency_menu.map((currency) => (
                            <option key={currency.value} value={currency.value}>
                              {" "}
                              {translate(
                                currency?.title_en,
                                currency?.title_ar,
                              )}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="w-full md:w-8/12 mt-4 md:mt-0">
                      <InputComponent
                        label={translate(
                          annual_income_label?.title_en,
                          annual_income_label?.title_ar,
                        )}
                        name="amount"
                        value={formData.amount}
                        handleChange={handleChange}
                        error={errors.amount}
                        type="text"
                        data-testid="amount"
                      />
                    </div>
                    <div className="yearsField md:w-4/12">
                      <InputComponent
                        label={translate(
                          no_of_years_label?.title_en,
                          no_of_years_label?.title_ar,
                        )}
                        name="numberOfYears"
                        value={formData.numberOfYears}
                        handleChange={handleChange}
                        error={errors.numberOfYears}
                        type="text"
                        data-testid="numberOfYears"
                        placeHolder={translate("Years", "سنين")}
                      />
                    </div>
                  </div>
                </div>
                <GoogleReCaptcha onVerify={(token) => {
                       if (token) {
                        setReCaptchaVerified(true);
                      }
                    }} />
                <div className="button-div">
                  {action_btn && !action_btn.is_hide && (
                    <button
                      disabled={!reCaptchaVerified}
                      type="submit"
                      className={`w-full primary-button md:w-auto text-white font-bold py-2 px-4 rounded`}
                      data-testid="submit">
                      {translate(action_btn?.label_en, action_btn?.label_ar)}
                    </button>
                  )}
                </div>
              </form>
                 </GoogleReCaptchaProvider>
            ) : (
              <CoverageCalculatorResult
                buttons={result_action_btns}
                coverageResultData={coverageResultData}
                formData={formData}
                disclaimer={result_disclaimer_text}
                handleSecondary={handleSecondary}
                handlePrimary={toggleModal}
              />
            )}
          </div>
          <div className={`order-1 md:order-1 md:col-span-1 rightPanel`}>
            <img
              src={imageURL}
              alt={translate(banner?.img_alt_en, banner?.img_alt_ar)}
            />
          </div>
        </div>
        <SpeakAdvisorModal
          showModal={isSpeakToAdvisor}
          toggleModal={toggleModal}
        />
        <Loader show={isSubmitDisabled} />
      </div>
    </>
  );
};

export default CoverageCalculator;
